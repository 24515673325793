
import React, { useEffect, useState } from 'react';
import { Button, Popup, SelectBox, TextBox } from 'devextreme-react';
import { useTranslation } from 'react-i18next';
import notify from 'devextreme/ui/notify';
import { parameterRequest } from '../../../../api/requests';
import { countryCodes } from '../../../../utils/countries';

export const SupplierParametersRowUpdatePopup = ({visible, onHiding, refresh, supplierParameter}) => {
   const [parameter, setParameter] = useState(supplierParameter);
    const [parameterState, setParameterState] = useState(supplierParameter);
    const { t } = useTranslation();

    useEffect(() => {
      setParameter(supplierParameter);
      setParameterState(supplierParameter);
      setReadOnly(true)
    }, [visible]);

  const updateField = (key) => (value) => {
    setParameter(prev => ({
      ...prev,
      [key]: value
    }));
  };

  function onSaveClick(){

    parameterRequest(parameter,2).then((res)=>{
      if(res.resCode == 200){
        refresh();
        notify(t("successfull"),'success');
        onHiding();

      }else{
        notify(res.resMessage,'error');
      }
    });

  }

 function onCancelClick() {
     setReadOnly(true)
     setParameter(parameterState)
   }


 const [readOnly, setReadOnly] = useState(true);


  return (
    <Popup
    visible={visible}
    onHiding={onHiding}
    width={1000}
    height={"auto"}
    maxHeight={"95%"}
    titleRender={()=>(
      <div className="flex flex-row justify-between py-2">

        <div className="flex flex-row">
            <Button icon="close" stylingMode="text" onClick={onHiding}></Button>
            <p className="ml-2 mt-0.5 justify-center font-bold text-lg">{supplierParameter.groupRef == 0 ? t("new") : supplierParameter.registrationName }</p>
        </div>

       <div className='flex flex-row gap-x-2'>
          <Button visible={readOnly} text={t("edit")} icon='edit' stylingMode='contained' type='default' onClick={()=>setReadOnly(false)} />
          <Button visible={readOnly} text={t("close")} icon='close' stylingMode='contained' style={{backgroundColor:"gray"}} type='default' onClick={onHiding} />
          <Button visible={!readOnly} text={t("save")} icon='save' stylingMode='contained' type='default' onClick={onSaveClick} />
          <Button visible={!readOnly} text={t("cancel")} icon='close' stylingMode='contained' style={{backgroundColor:"gray"}} type='default' onClick={onCancelClick} />
        </div>

      </div>
    )}
    contentRender={()=>
    (
      <div className='flex flex-col gap-y-3 p-2'>


        <div className='flex gap-4'>

                <div className='flex flex-col w-full gap-3 border shadow-md p-3'>
                              <TextBox
                                value={parameter?.arpCode}
                                onValueChange={updateField('arpCode')}
                                label={t("code")}
                                disabled
                                readOnly= {readOnly}
                              />

                              <TextBox
                                label={t("street") }
                                value={parameter?.arpStreet}
                                onValueChange={updateField('arpStreet')}
                                readOnly= {readOnly}
                              />

                              <TextBox
                                value={parameter?.arpCity}
                                onValueChange={updateField('arpCity')}
                                label={t("city") }
                                readOnly= {readOnly}
                              />

                              <TextBox
                                value={parameter?.arpPostCode}
                                onValueChange={updateField('arpPostCode')}
                                label={t("postCode") }
                                readOnly= {readOnly}
                              />

                              {
                                /*
                              <SelectBox
                                                label={t("country")}
                                                items={countryCodes}
                                                value={parameter?.arpCountry}
                                                onValueChanged={(e) => {
                                                  const selectedCountry = countryCodes.find((item) => item.name === e.value);
                                                  if (selectedCountry) {
                                                    updateField("arpCountry")(selectedCountry.name);
                                                    updateField("arpCountryCode")(selectedCountry.code);
                                                  }
                                                }}
                                                valueExpr="name"
                                                displayExpr="name"
                                                placeholder={t("search")}
                                                searchEnabled={true}
                                              />
                                */
                              }


                              <TextBox
                                value={parameter?.arpCountry}
                                onValueChange={updateField('arpCountry')}
                                label={t("country") }
                                readOnly= {readOnly}
                              />

                              <TextBox
                                value={parameter?.arpCountryCode}
                                onValueChange={updateField('arpCountryCode')}
                                label={t("countryCode") }
                                hint='Country code MUST be coded using ISO code list 3166-1'
                                readOnly= {readOnly}
                              />


                            </div>

                            <div className='flex flex-col w-full gap-3 border shadow-md p-3'>

                              <TextBox
                                value={parameter?.contactName}
                                onValueChange={updateField('contactName')}
                                label={t("contactName")}
                                readOnly= {readOnly}
                              />

                              <TextBox
                                value={parameter?.contactPhone}
                                onValueChange={updateField('contactPhone')}
                                label={t("contactPhone")}
                                readOnly= {readOnly}

                              />

                              <TextBox
                                value={parameter?.arpEMail}
                                onValueChange={updateField('arpEMail')}
                                label={t("email") }
                                readOnly= {readOnly}
                              />

                              <TextBox
                                value={parameter?.registrationName}
                                onValueChange={updateField('registrationName')}
                                hint='Firm official name or owner name'
                                label={t("registrationName") }
                                readOnly= {readOnly}
                              />

                              <TextBox
                                value={parameter?.registerNumber}
                                onValueChange={updateField('registerNumber')}
                                label={t("registerNumber")}
                                readOnly= {readOnly}

                              />

                              <TextBox
                                value={parameter?.legalForm}
                                onValueChange={updateField('legalForm')}
                                label={t("legalForm")}
                                readOnly= {readOnly}

                              />
                            </div>

                            <div className='flex flex-col w-full gap-3 border shadow-md p-3'>
                              <TextBox
                                value={parameter?.arpIban}
                                onValueChange={updateField('arpIban')}
                                label={t("iban")}
                                readOnly= {readOnly}
                              />

                              <TextBox
                                value={parameter?.leitwegId}
                                onValueChange={updateField('leitwegId')}
                                label={t("leitwegId")}
                                readOnly= {readOnly}
                              />

                              <TextBox
                                value={parameter?.endpointId}
                                onValueChange={updateField('endpointId')}
                                label={t("endpointId")}
                                readOnly= {readOnly}
                              />

                              <TextBox
                                value={parameter?.arpVatId}
                                onValueChange={updateField('arpVatId')}
                                label={t("vatNumber")}
                                readOnly= {readOnly}
                              />

                              <TextBox
                                value={parameter?.arpTaxNr}
                                onValueChange={updateField('arpTaxNr')}
                                label={t("taxNr")}
                                readOnly= {readOnly}
                              />


                            </div>

                    </div>

    </div>
    )}>

    </Popup>
  );
};
