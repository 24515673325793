import axios from "axios";
import { getVendorId } from "../api/sessions";
import { base64Decode, base64Encode } from "./base64-decoder";

export async function kosit_validator(xmlFile) {
    const vend = getVendorId();
    const vendENC = base64Encode(vend);
    try {
        const formData = new FormData();
        formData.append("xml", xmlFile);
        formData.append("vendorId", vendENC??"vendorNotFound");

        const request = await axios.post("https://apis.mobiapi.de:4248/validate", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return request.data;
    } catch (error) {
        console.error("Validation API error:", error);
        return { resCode: 500, resMessage: "Fail", success: false, message: "Validation failed" };
    }
}