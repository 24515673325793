import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { DataGrid, Column, DataGridTypes, Editing, Scrolling, SearchPanel, ColumnChooser, Export, HeaderFilter, Sorting, Selection, Toolbar, Item, FilterPanel, FilterBuilderPopup, DataGridRef, RowDragging } from 'devextreme-react/data-grid';
import { campaignProductDeleteRequest, campaignProductsImportRequest, campaignProductUpdateRequest, generalCampaignProductListRequest } from '../../../api/requests';
import { Button, CheckBox, DropDownButton, LoadPanel } from 'devextreme-react';
import ProductSearch from './searchProduct';
import notify from 'devextreme/ui/notify';
import { useScreenSize } from '../../../utils/media-query';
import { csv_to_json } from '../../../utils/cvs_to_json-converter';
import { useTranslation } from 'react-i18next';
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import { appInfo, company } from '../../../app-info';
import { confirm } from 'devextreme/ui/dialog';

const CampaignProductList = ({ campaignRef, campaignProductListRefresh, readOnly, expandTable, setExpandTable, isPopupOpened }) => {
    const [products, setProducts] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const gridRef = useRef<DataGridRef>(null);
    const {t} = useTranslation();

    function getList() {
      if(campaignRef != 0){
        gridRef.current!.instance().beginCustomLoading(t("loading"));
        generalCampaignProductListRequest(campaignRef)
            .then((response) => {
                setProducts(response.dataSet || []);
            })
            .catch((error) => {
                console.error('Error fetching product list:', error);
            }).finally(()=>{
              gridRef.current!.instance().endCustomLoading();
            })
      }
    }

    useEffect(() => {
        getList();
    }, [campaignRef, campaignProductListRefresh]);

    const onRowUpdating = (e) => {
        const updatedData = { ...e.oldData, ...e.newData };
        const { erpItemPrice } = updatedData;

         // campaignRunManually alanını kontrol et ve güncelle
        if (e.newData.campaignRunManually !== undefined) {
            updatedData.campaignRunManually = e.newData.campaignRunManually ? 1 : 0;
        }

        // Calculate discount based on price change
        if (e.newData.campaignPrice !== undefined && erpItemPrice && erpItemPrice != 0) {
            updatedData.campaignPercent = parseFloat(((erpItemPrice - e.newData.campaignPrice) / erpItemPrice * 100).toFixed(1));
        }else

        // Calculate price based on discount change
        if (e.newData.campaignPercent !== undefined ) {
            updatedData.campaignPrice = parseFloat((erpItemPrice - (erpItemPrice * e.newData.campaignPercent / 100)).toFixed(3));
        }

        e.newData = updatedData;

        campaignProductUpdateRequest(updatedData).then((response) => {
            if (response.resCode === 200) {
                notify(t("updateSuccessfull"), "success", 1000);
            } else {
                notify(response["resMessage"], "error", 2500);
                getList();
            }
        });
    };

    const onRowRemoving = (e) => {
        const product = e.data;

        return campaignProductDeleteRequest(product)
            .then((response) => {
                if (response.resCode === 200) {
                    notify(t("successfull"), "success", 1000);
                } else {
                    notify(response.resMessage, "error", 2500);
                    getList();
                    throw new Error(response.resMessage);
                }
            })
            .catch((error) => {
                console.error('Error deleting product:', error);
            });
    };

    const [isPanelOpened, setPanelOpened] = useState(false);
    const {isMedium,isLarge} = useScreenSize();

    const changeSelectProductPopup = useCallback(() => {
        if(!readOnly){
            notify(t("saveAboveInformation"),"warning");
        }else{
            setPanelOpened(!isPanelOpened);
        }
    }, [isPanelOpened,readOnly]);

    const renderHeaderWithWrap = ({ column }) => {
        return (
            <div style={{ whiteSpace: 'normal', textAlign: 'center', overflow: 'hidden', textOverflow: 'ellipsis' ,fontSize: isLarge?"13px":"9px" }}>
                {column.caption}
            </div>
        );
    };


    const products_import = () => {
       csv_to_json().then((json)=>{
        var list = JSON.parse(json);
        campaignProductsImportRequest(campaignRef,list).then((response)=>{
            if(response.resCode == 200){
                notify(t("successfull"),"success");
            }else{
                notify(response.resMessage,"error")
            }
            getList();
        })
       });
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const onChangesChange = useCallback((changes: DataGridTypes.DataChange[]) => {

        dispatch({ type: 'SET_CHANGES', changes });
      }, []);


    const onEditRowKeyChange = useCallback((editRowKey: number | null) => {
      dispatch({ type: 'SET_EDIT_ROW_KEY', editRowKey });
    }, []);

    const onExporting = (e: DataGridTypes.ExportingEvent) => {
        if (e.format === 'pdf') {
          const doc = new JsPdf();
          exportDataGridToPdf({
            jsPDFDocument: doc,
            component: e.component,
          }).then(() => {
            doc.save('Products.pdf');
          });
        } else {
          const workbook = new Workbook();
          const worksheet = workbook.addWorksheet('Page1');

          exportDataGridToXLSX({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
          }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'CampaignProducts.xlsx');
            });
          });
          e.cancel = true;
        }
      };

      const onSelectionChanged = (e) => {
        setSelectedRows(e.selectedRowsData);
    };

      async function deleteSelected(){
        const result = await confirm(t("areYouSureDeleting"),t("deleteProcess"));
          if (result) {
            gridRef.current?.instance().beginCustomLoading(t("loading"))
            var i = 0;
            selectedRows.forEach((row, index) => {

                campaignProductDeleteRequest(row).then((response)=>{
                    if(response["resCode"]==200){
                    }else{
                        notify(response["resMessage"],"error");
                    }
                    i++;
                    if(i==selectedRows.length){
                      gridRef.current?.instance().endCustomLoading();
                        getList();

                    }
                });
            });
          }
      }

    function expandTableFunc(){
      if(expandTable){
        setExpandTable(false)
      }else{
        setExpandTable(true)
      }
    }

    const onReorder = (e) => {
      e.promise = processReorder(e);
    };

    const processReorder = async (e) => {
      const oldData = e.itemData;

      oldData.newRouteLineNr = e.toIndex + 1;
    /*
      await salesRouteLineUpdateRequest(oldData).then((response)=>{
        request();
      })
    */
      await e.component.refresh();

      };

    const [rowCount, setRowCount] = useState(0);
    const handleContentReady = (e) => {
      setRowCount(e.component.totalCount());
    };

    return (
        <>
            <div>
                <DataGrid
                className='custom-grid'
                    dataSource={products}
                    ref={gridRef}
                    allowColumnReordering
                    keyExpr="campaignProductRef"
                    onExporting={onExporting}
                    onRowUpdating={onRowUpdating}
                    onRowRemoving={onRowRemoving}
                    onSelectionChanged={onSelectionChanged}
                    onContentReady={handleContentReady}

                    allowColumnResizing
                    rowAlternationEnabled
                    noDataText={t("noData")}
                    repaintChangesOnly
                    height={expandTable?"calc(95vh - 120px)":"calc(95vh - 350px)"}
                            >
                    <Toolbar>
                    <Item name='searchPanel' location='before'  />

                    <Item location='before' locateInMenu='auto'>
                    <Button
                            icon='refresh'
                            stylingMode='text'
                            hint={t("refresh")}
                            onClick={getList}
                        />
                    </Item>

                    <Item name='columnChooserButton' location='before' />

                    <Item name='exportButton' location='before' />

                    <Item location="before">
                      <DropDownButton
                      visible={selectedRows.length>0}
                        text={t("transactions")}
                        icon="menu"
                        width={"150"}
                        items={[
                          { text: t("deleteSelected"), icon: 'trash', action: 'deleteSelected' },
                        ]}
                        onItemClick={(e) => {
                          if(e.itemData.action=="deleteSelected"){
                            deleteSelected();
                          }
                        }}
                      />
                    </Item>

                    <Item location='center' locateInMenu='auto'>
                                    {rowCount} {t("records")}
                                </Item>

                    <Item location='after' locateInMenu='auto'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>

                        <Button
                            icon="plus"
                            type="default"
                            stylingMode="contained"
                            hint={t("selectProducts")}
                            onClick={changeSelectProductPopup}
                            disabled={!readOnly}
                            />

                            <div style={{ marginLeft: '10px' }}>
                            <Button
                                icon="import"
                                type="default"
                                hint={t("importFolder")}
                                stylingMode="contained"
                                onClick={products_import}
                                disabled={!readOnly}
                            />
                            </div>

                            <div style={{ marginLeft: '10px' }}>
                            <Button
                                icon={expandTable?"chevrondown":"fullscreen"}
                                type="default"
                                hint={t("expandTable")}
                                stylingMode="contained"
                                onClick={expandTableFunc}
                                disabled={!readOnly}
                            />
                            </div>

                        </div>
                    </Item>

                    </Toolbar>


                    <LoadPanel showPane={false}  />
                    <SearchPanel visible placeholder={t("search")} width={"300px"} />
                    <ColumnChooser  enabled  title={t("columnChooser")}/>
                    <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
                    <HeaderFilter visible />
                    <Sorting mode='multiple' />
                    <Scrolling mode='virtual' />
                    <Selection selectAllMode='allPages' showCheckBoxesMode='always' mode='multiple' />
                    <FilterPanel visible={true} />
                    <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />
                    <RowDragging allowReordering={true} onReorder={onReorder} dropFeedbackMode="push" />



                    <Editing
                    mode="row"
                    allowDeleting
                    allowUpdating
                    texts={{
                        editRow: t("edit"),
                        deleteRow: t("delete"),
                        confirmDeleteMessage: t("confirmDeleteMessage"),
                        confirmDeleteYes: t("yes"),
                        cancelRowChanges: t("no"),
                    }}
                    />

                    <Column dataField="itemCode"            caption={t("productCode")}        allowEditing={false}    width={120}       headerCellRender={renderHeaderWithWrap} />
                    <Column dataField="itemName"            caption={t("productName")}        allowEditing={false}    minWidth={160}    headerCellRender={renderHeaderWithWrap} />
                    <Column dataField="erpItemSRP"          caption={t("SRP")}                allowFiltering={false} allowEditing={company=="akar"} width={100}     dataType="number"   headerCellRender={renderHeaderWithWrap} />
                    <Column dataField="campaignSRP"         caption={t("campaignSRP")}        allowFiltering={false}  width={100} dataType="number" headerCellRender={renderHeaderWithWrap} />
                    <Column dataField="erpItemPrice"        caption={t("price")}              allowFiltering={false} allowEditing={company=="akar"} width={100} dataType="number"   headerCellRender={renderHeaderWithWrap} />
                    <Column dataField="campaignPrice"       caption={t("campaignPrice")}      allowFiltering={false}  width={100} dataType="number" headerCellRender={renderHeaderWithWrap} />
                    <Column dataField="campaignPercent"     caption={t("campaignPercent")}    allowFiltering={false}  width={100} dataType="number" headerCellRender={renderHeaderWithWrap} />
                    <Column dataField="erpItemCost"         caption={t("erpItemCost")}        allowFiltering={false} allowEditing={company=="akar"}  width={100} dataType="number" visible={false}   headerCellRender={renderHeaderWithWrap} />
                    <Column dataField="campaignItemAmount"  caption={t("campaignItemAmount")} allowFiltering={false}  width={100} dataType="number"  headerCellRender={renderHeaderWithWrap} />
                    <Column dataField="campaignMinAmount"   caption={t("campaignMinAmount")}  allowFiltering={false}  width={100} dataType="number"  headerCellRender={renderHeaderWithWrap} />
                    <Column dataField="campaignMaxAmount"   caption={t("campaignMaxAmount")}  allowFiltering={false}  width={100} dataType="number"  headerCellRender={renderHeaderWithWrap} />
                    {
                      <Column
                        dataField="showOnStart"
                        caption={t("showOnStart")}
                        dataType="boolean"
                        allowFiltering={false} width={100} allowEditing={true}
                        alignment='center'
                        headerCellRender={renderHeaderWithWrap}
                        calculateCellValue={(data) => !!data.showOnStart}
                        setCellValue={(newData, value) => {
                            newData.showOnStart = value ? 1 : 0;
                        }}
                      />
                    }
                    <Column dataField="comment" caption={t("comment")} allowFiltering={false} allowSorting={false} width={140} headerCellRender={renderHeaderWithWrap} />


                </DataGrid>
            </div>

                    <ProductSearch visible={isPanelOpened} onHiding={changeSelectProductPopup} campaignRef={campaignRef} getList={getList} />
        </>
    );
};

export default CampaignProductList;


type State = {
  data: any[];
  changes: DataGridTypes.DataChange[];
  editRowKey: number | null;
};

type Action =
  | { type: 'SET_CHANGES'; changes: DataGridTypes.DataChange[] }
  | { type: 'SET_EDIT_ROW_KEY'; editRowKey: number | null };

const initialState: State = {
  data: [], // Burada başlangıç verilerinizi yükleyebilirsiniz.
  changes: [],
  editRowKey: null,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_CHANGES':
      return { ...state, changes: action.changes };
    case 'SET_EDIT_ROW_KEY':
      return { ...state, editRowKey: action.editRowKey };
    default:
      return state;
  }
};