import React, { useState, useEffect, useRef } from 'react';
import { Popup } from 'devextreme-react/popup';
import DataGrid, {
  Sorting, Selection, HeaderFilter, Scrolling, SearchPanel,
  ColumnChooser, Column, Toolbar, Item, LoadPanel,
  Export,
  DataGridTypes,
  FilterPanel,
  FilterBuilderPopup,
  DataGridRef,

} from 'devextreme-react/data-grid';

import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';

import { Button } from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import { useTranslation } from 'react-i18next';
import { incomeInvoiceLookupCustomerRequest } from '../../../../api/requests';
import { appInfo } from '../../../../app-info';

const SelectSupplierPopup = ({ visible, onHidingg, setSelectedSupplier }) => {
    const [suppliers, setSuppliers] = useState([]);
    const {t} = useTranslation();
    const gridRef = useRef<DataGridRef>(null);
    const [rowCount, setRowCount]= useState(0);
    const [selectedRow, setSelectedRow] = useState();

    useEffect(() => {
        setIsRowSelected(false)
      if(visible){
        getListFromApi();
      }
      clearSelections();
  }, [visible]);

  function getListFromApi(){
    gridRef.current!.instance().beginCustomLoading(t("loading"));
    incomeInvoiceLookupCustomerRequest().then((resp)=>{
        if(resp.resCode == 200){
          setSuppliers(resp.supplierNames);
        }else{
          notify(resp.resMessage,"error");
        }
      }).finally(()=>{
    gridRef.current!.instance().endCustomLoading();
  });
  }

    const onAddButtonClick = () => {
        setSelectedSupplier(selectedRow);
        onHidingg();
    };

    const clearSelections = () => {
        gridRef.current?.instance().clearFilter();
        gridRef.current?.instance().clearSelection();
    };

    const handleContentReady = (e) => {
        setRowCount(e.component.totalCount());
      };

    const onExporting = (e: DataGridTypes.ExportingEvent) => {
        if (e.format === 'pdf') {
          const doc = new JsPdf();
          exportDataGridToPdf({
            jsPDFDocument: doc,
            component: e.component,
          }).then(() => {
            doc.save('Suppliers.pdf');
          });
        } else {
          const workbook = new Workbook();
          const worksheet = workbook.addWorksheet('Page1');

          exportDataGridToXLSX({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
          }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Suppliers.xlsx');
            });
          });
          e.cancel = true;
        }
      };

      const [isRowSelected, setIsRowSelected] = useState(false);
      const onSelectionChanged = (e) => {
        setIsRowSelected(true);
        try{
            if(e.selectedRowsData[0]){
                setSelectedRow(e.selectedRowsData[0]);
            }
        }catch(e){console.error(e)}

    };

    return (
        <Popup
          visible={visible}
          onHiding={onHidingg}
          title={t("selectSupplier")}
          width={"85%"}
          height={"85%"}
          dragEnabled={true}
          titleRender={()=>(
            <div className="flex flex-row justify-between">
              <div className="flex flex-row">
              <Button icon="close" stylingMode="text" onClick={onHidingg}></Button>
              <p className="ml-2 mt-0 justify-center font-bold text-lg">{t("products")}</p>
              </div>

            </div>
          )}
          showCloseButton={true}

        >

        <DataGrid
          ref={gridRef}
          dataSource={suppliers}
          keyExpr="erpSupplierRef"
          showBorders={true}
          onExporting={onExporting}
          onSelectionChanged={onSelectionChanged}
          onContentReady={handleContentReady}
          noDataText={t("noData")}
          columnAutoWidth={false}
          allowColumnReordering
          allowColumnResizing
          height={"100%"}
          >


          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <LoadPanel showPane={false}  />
          <SearchPanel placeholder={t("search")} searchVisibleColumnsOnly={false} width={"300px"} visible={true} />
          <ColumnChooser title={t("columnChooser")} enabled />
          <Selection  mode='single' />
          <HeaderFilter visible />
          <Scrolling mode='virtual' />
          <LoadPanel showPane={true}  />
          <FilterPanel visible={true} />
          <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />

          <Toolbar>

          <Item name='searchPanel' location='before'  />


            <Item location='before' locateInMenu='auto'>
                <Button
                        icon='refresh'
                        stylingMode='text'
                        hint={t("refreshList")}
                        onClick={getListFromApi}
                    />
                </Item>

          <Item location='before' name='columnChooserButton' locateInMenu='auto' />

          <Item name='exportButton' location='before' />

          <Item location='center' locateInMenu='auto'>
          <p>{rowCount} {t("records")}</p>
          </Item>

          <Item location='after' locateInMenu='auto'>
          <div className="flex flex-row gap-x-1">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      icon='check'
                      text={t("select")}
                      type='default'
                      stylingMode='contained'
                      visible={true}
                      onClick={onAddButtonClick}
                      disabled={!isRowSelected}
                    />
                    <div style={{ marginLeft: '10px' }}>
                      <Button
                      text={t("close")}
                      icon='close'
                      stylingMode='contained'
                      style={{backgroundColor:"gray"}}
                      type='default'
                      onClick={onHidingg}
                    />
                    </div>
                  </div>
                </div>
          </Item>


          </Toolbar>

          <Column dataField='supplierCode' caption={t("supplierCode")} />
          <Column dataField='supplierName' caption={t("supplierName")}  />
          <Column dataField='supplierCity' caption={t("supplierCity")}  />

        </DataGrid>
        </Popup>
      );
};

export default SelectSupplierPopup;
