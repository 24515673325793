import React from "react";
import { formatPriceAmount } from "./formats";
const invoicePreviewBody = (data, t) => {
  const {
    invoiceGeneral,
    supplierData,
    buyerData,
    paymentInformation,
    invoiceLines,
    legalMonetaryTotal,
    taxTotal,
  } = data;

  const invoice = invoiceGeneral[0];
  const supplier = supplierData[0];
  const buyer = buyerData[0];
  const payment = paymentInformation[0];
  const monetary = legalMonetaryTotal[0];
  const tax = taxTotal[0];

  return (
    <div style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.5" }}>
      <h4 style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "10px" }}>
        {t("invoiceDetails")}: {invoice.invoiceID}
      </h4>
      <b>{buyer.buyerRegistrationName}</b>
      <p>{invoice.invoiceID}</p>

      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px", marginTop: "20px" }}>
        {/* Supplier Info */}
        <div>
          <div style={{ fontSize: "14px", fontWeight: "bold", textDecoration: "underline", marginBottom: "5px" }}>
            {t("supplierInfo")}
          </div>
          <ul style={{ listStyle: "none", padding: 0 }}>
            <li>
              <strong>{t("supplierName")}:</strong> {supplier.supplierName}
            </li>
            <li>
              <strong>{t("address")}:</strong> {supplier.supplierStreetName} <br></br>
              <span style={{visibility: "hidden"}}> {t("address")}:a</span>{supplier.supplierPostalZone} {supplier.supplierCityName}, {supplier.supplierCountryCode}
            </li>
            <li>
              <strong>{t("VAT")}:</strong> {supplier.supplierVATID}
            </li>
          </ul>
        </div>

        {/* Invoice Info */}
        <div>
          <div style={{ fontSize: "14px", fontWeight: "bold", textDecoration: "underline", marginBottom: "5px" }}>
            {t("invoiceInfo")}
          </div>
          <ul style={{ listStyle: "none", padding: 0 }}>
            <li>
              <strong>{t("date")}:</strong> {new Date(invoice.issueDate).toLocaleDateString()}
            </li>
            <li>
              <strong>{t("invoiceType")}:</strong> {invoice.slipTypeCode ? invoice.slipTypeCode : "N/A"}
            </li>
            <li>
              <strong>{t("currency")}:</strong> {invoice.invoiceCurrency}
            </li>
          </ul>
        </div>

        {/* Buyer Info */}
        <div>
          <div style={{ fontSize: "14px", fontWeight: "bold", textDecoration: "underline", marginBottom: "5px" }}>
            {t("buyerInfo")}
          </div>
          <ul style={{ listStyle: "none", padding: 0 }}>
            <li>
              <strong>{t("name")}:</strong> {buyer.buyerRegistrationName}
            </li>
            <li >
              <strong>{t("address")}:</strong> {buyer.buyerStreetName} <br></br>
             <span style={{visibility: "hidden"}}> {t("address")}:a</span>{buyer.buyerPostalZone}  {buyer.buyerCityName}, {buyer.buyerCountryCode}
            </li>
          </ul>
        </div>

        {/* Payment Info */}
        <div>
          <div style={{ fontSize: "14px", fontWeight: "bold", textDecoration: "underline", marginBottom: "5px" }}>
            {t("paymentInfo")}
          </div>
          <ul style={{ listStyle: "none", padding: 0 }}>
            <li>
              <strong>{t("paymentChoice")}:</strong> {payment.paymentMeansCode}
            </li>
            <li>
              <strong>{t("iban")}:</strong> {payment.supplierIBAN}
            </li>
          </ul>
        </div>
      </div>

      <div style={{ fontSize: "14px", fontWeight: "bold", marginTop: "20px" }}>{t("products")}</div>
      <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid black", marginTop: "10px" }}>
        <thead>
          <tr>
            {[
              t("order"),
              t("productCode"),
              t("productName"),
              t("amount"),
              t("unitContent"),
              t("perPrice"),
              t("total"),
              t("VAT"),

            ].map((header, index) => (
              <th key={index} style={{ border: "1px solid black", padding: "5px" }}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {invoiceLines &&
            invoiceLines.map((line, index) => (
              <tr key={index}>
                <td style={{ border: "1px solid black", padding: "5px", textAlign: "right" }}>{line.lineNr}</td>
                <td style={{ border: "1px solid black", padding: "5px", textAlign: "left" }}>
                  {line.sellersItemIdentification}
                </td>
                <td style={{ border: "1px solid black", padding: "5px", textAlign: "left" }}>{line.itemName}</td>

                <td style={{ border: "1px solid black", padding: "5px", textAlign: "right" }}>
                  {line.lineQuantity / line.itemBaseQuantity}
                </td>

                <td style={{ border: "1px solid black", padding: "5px", textAlign: "right" }}>
                  {line.itemBaseQuantity}
                </td>

                <td style={{ border: "1px solid black", padding: "5px", textAlign: "right" }}>
                  {formatPriceAmount(line.itemPriceAmount)}
                </td>

                <td style={{ border: "1px solid black", padding: "5px", textAlign: "right" }}>
                  {formatPriceAmount(line.lineExtensionAmount)}
                </td>

                <td style={{ border: "1px solid black", padding: "5px", textAlign: "right" }}>
                  {`${line.itemTaxPercent}%`}
                </td>


              </tr>
            ))}
        </tbody>
      </table>

      <div style={{ paddingTop: "10px", }}>
        <ul style={{ listStyle: "none", padding: 0, marginTop: "20px", marginBottom: "20px" }}>
          <li style={{ display: "flex", justifyContent: "right", marginBottom: "5px" }}>
            <strong style={{marginRight: "30px"}}>{t("totalExtensionAmount")}:</strong>
            <span style={{width: "150px", textAlign:"right"}}>
              {formatPriceAmount(monetary.totalExtensionAmount)} {invoice.invoiceCurrency}
            </span>
          </li>
          <li style={{ display: "flex", justifyContent: "right", marginBottom: "5px" }}>
            <strong style={{marginRight: "30px", textAlign:"left"}}>{t("allowanceTotalAmount")}:</strong>
            <span style={{width: "150px", textAlign:"right"}}>
              {formatPriceAmount(monetary.allowanceTotalAmount)} {invoice.invoiceCurrency}
            </span>
          </li>
          <li style={{ display: "flex", justifyContent: "right", marginBottom: "5px" }}>
            <strong style={{marginRight: "30px"}}>{t("priceExceptVat")}:</strong>
            <span style={{width: "150px", textAlign:"right"}}>
              {formatPriceAmount(monetary.taxExclusiveAmount)} {invoice.invoiceCurrency}
            </span>
          </li>
          <li style={{ display: "flex", justifyContent: "right", marginBottom: "5px" }}>
            <strong style={{marginRight: "30px"}}>{t("VAT")}:</strong>
            <span style={{width: "150px", textAlign:"right"}}>
              {formatPriceAmount(tax.totalTaxAmount)} {invoice.invoiceCurrency}
            </span>
          </li>
          <li style={{ display: "flex", justifyContent: "right", marginBottom: "5px" }}>
            <strong style={{marginRight: "30px"}}>{t("generalSum")}:</strong>
            <strong style={{width: "150px", textAlign:"right"}}>
              {formatPriceAmount(monetary.taxInclusiveAmount)} {invoice.invoiceCurrency}
            </strong>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default invoicePreviewBody;
