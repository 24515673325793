import React from 'react';
import Chart, {
  ArgumentAxis,
  Legend,
  Series,
  ValueAxis,
  Label,
  Export,
  Tick,
  Size,
  Format,
} from 'devextreme-react/chart';
import { CardAnalytics } from '../card-analytics/CardAnalytics';
import { useTranslation } from 'react-i18next';


function DashboardChart1({data}) {
  const {t} = useTranslation();
  const sortedData = data&&[...data].sort((a, b) => a.lineTotal - b.lineTotal);
  return (
    <CardAnalytics title={t("topCustomers")} contentClass='sales-by-category'>

    <Chart
      dataSource={sortedData}
      rotated={true}
      id="chart"
    >
      <Size height={270} />


      <Series
        valueField="lineTotal"
        argumentField="lineName"
        type="bar"
        color="#79cac4"

      >
      <Label visible={false} backgroundColor="#c18e92" />
      </Series>

      <Legend visible={false} verticalAlignment="bottom" horizontalAlignment="right" />

      <Export enabled={false} />

      <ValueAxis>
        <Label>
          <Format type='currency' currency='EUR' />
        </Label>
      </ValueAxis>

    </Chart>
    </CardAnalytics>
  );
}

export default DashboardChart1;
