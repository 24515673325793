// Encode (Görünür ID)
export function base64Encode(text) {
    if (typeof window === 'undefined') {
      // Node.js ortamı
      return Buffer.from(text, 'utf-8').toString('base64');
    } else {
      // Tarayıcı ortamı
      return btoa(new TextEncoder().encode(text).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    }
  }

  // Decode (Orijinal ID'ye dön)
  export function base64Decode(base64) {
    if (!base64) {
      return '';
    }

    if (typeof window === 'undefined') {
      // Node.js ortamı
      return Buffer.from(base64, 'base64').toString('utf-8');
    } else {
      // Tarayıcı ortamı
      return new TextDecoder().decode(
        Uint8Array.from(atob(base64), (char) => char.charCodeAt(0))
      );
    }
  }
