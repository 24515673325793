import React, { useCallback, useEffect, useRef, useState } from "react";
import { getInvoice, invoiceStatusChangeRequest, jsonToXmlRequest, OutgoingInvoicesRequest } from "../../../api/requests";
import notify from "devextreme/ui/notify";
import { Button, DataGrid, DateBox, DropDownButton, LoadPanel, Popup, } from "devextreme-react";
import { useTranslation } from "react-i18next";
import { Column, ColumnChooser, DataGridRef, Export, Toolbar, FilterBuilderPopup, FilterPanel, HeaderFilter, Item, Scrolling, SearchPanel, Selection, Sorting, DataGridTypes } from "devextreme-react/cjs/data-grid";
import { appInfo } from "../../../app-info";
import axios from "axios";
import invoicePreviewBody from "../../../utils/invoicePreviewDesign";
import { formatDateDMY, formatPriceAmount } from "../../../utils/formats";
import { DropDownButtonTypes } from "devextreme-react/cjs/drop-down-button";
import { downloadBase64PDF, openBase64PDFInNewTab, } from "../../../utils/fileController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode, faEnvelope, faEye, faFileImport, faFileInvoice, faFilePdf, faMoneyBill, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { getAppToken, getVendorId } from "../../../api/sessions";
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import '../../../theme/grid.scss';



export const OutgoingInvoicesList = () => {
  const gridRef = useRef<DataGridRef>(null);
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  function getList() {
    gridRef.current?.instance().beginCustomLoading(t("loading"));
    OutgoingInvoicesRequest(startDate, endDate).then((res) => {
      if (res.resCode == 200) {
        setInvoices(res.dataset || []);
      } else {
        notify(res.resMessage,"error");
      }
      gridRef.current?.instance().endCustomLoading();
    })
  }

  useEffect(() => {
    getList();
  }, [])



  const [status, setStatus] = useState("all");
  const filterStatusList = ["all", "notSent", "sent", "approved"];
  function getTranslatedStatusList() {
    return filterStatusList.map((status) => t(status));
  }
  const filterByStatus = useCallback((e: DropDownButtonTypes.SelectionChangedEvent) => {

    const { item: selected }: { item } = e;
    if (selected == t("all")) {
      setStatus("all");
      gridRef.current?.instance().clearFilter();
    } else if (selected == t('notSent')) {
      setStatus("notSent");
      gridRef.current?.instance().filter(['eInvoiceStatus', '=', "0"]);
    } else if (selected == t('sent')) {
      setStatus("sent");
      gridRef.current?.instance().filter(['eInvoiceStatus', '=', "1"]);
    } else if (selected == t('approved')) {
      setStatus("approved");
      gridRef.current?.instance().filter(['eInvoiceStatus', '=', "2"]);
    }


  }, []);

  const handleContentReady = (e) => {
    setRowCount(e.component.totalCount());
  };

  const handleXMLDownload = async (erpInvoiceRef) => {
    try {

      const response = await getInvoice(erpInvoiceRef);

      if (response && response.resCode == 200) {
        const xmlContent = await jsonToXmlRequest(response); // XML'i oluştur
        const blob = new Blob([xmlContent], { type: 'text/xml' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${response.invoiceGeneral[0].invoiceID}.xml`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setLoadingXMLs((prev) => ({ ...prev, [erpInvoiceRef]: false }));

      } else {
        setLoadingXMLs((prev) => ({ ...prev, [erpInvoiceRef]: false }));
        console.error(response.resMessage);
        notify(response.resMessage, "error")
      }


    } catch (error) {
      setLoadingXMLs((prev) => ({ ...prev, [erpInvoiceRef]: false }));
      console.error('XML indirme hatası:', error);
      notify('XML indirme hatası', "error")
    }
  };

  function downloadSelectedPDF() {
    gridRef.current?.instance().beginCustomLoading(t("loading"));
    var i = 0;
    selectedRows.forEach((row, index) => {

      downloadBase64PDF(row["erpInvoiceNr"]).then(() => {
        i++;
        if (i == selectedRows.length) {
          gridRef.current?.instance().endCustomLoading();
        }
      });
    });
  }

  function downloadSelectedXML() {
    gridRef.current?.instance().beginCustomLoading(t("loading"));
    var i = 0;
    selectedRows.forEach((row, index) => {

      handleXMLDownload(row["erpInvoiceRef"]).then(() => {
        i++;
        if (i == selectedRows.length) {
          gridRef.current?.instance().endCustomLoading();
        }
      });
    });
  }

  function sendEmailSelected() {
    gridRef.current?.instance().beginCustomLoading(t("loading"));
    var i = 0;
    selectedRows.forEach((row, index) => {

      handleEmailSend(row["erpInvoiceRef"], row["arpLanguage"]).then((res) => {

        i++;
        if (i == selectedRows.length) {
          gridRef.current?.instance().endCustomLoading();
          setTimeout(()=>{
            getList();
          },250)

        }
      });
    });
  }

  const handleEmailSend = async (erpInvoiceRef,langCode) => {
    const response = await getInvoice(erpInvoiceRef);
    if (response && response.resCode == 200) {
     // const emailBodyHTML = ReactDOMServer.renderToStaticMarkup(invoicePreviewBody(response, t));
      try {
        const mailResponse = await axios.post("https://pay.mobiserver.de:4240/send-email", {
          json: response,
          vendorID: getVendorId(),
          token: getAppToken(),
          invoiceID: response["invoiceGeneral"][0]["invoiceID"],
          langCode: langCode
        });

        if (mailResponse.data.resCode == 200) {
          setLoadingMails((prev) => ({ ...prev, [erpInvoiceRef]: false }));
          notify(t("successfull"), "success")
         const resultt = await invoiceStatusChangeRequest(erpInvoiceRef, 1).then((res2)=>{
            if(res2.resCode == 200){
              return true;
            }else{
              return false;
            }
          });

            if(resultt){
              return true;
            }else{
              return false;
            }

        } else {
          setLoadingMails((prev) => ({ ...prev, [erpInvoiceRef]: false }));
          notify(mailResponse.data.resMessage, "error");
          return false;
        }

      } catch (error) {
        notify("Mail sending failed: " + error, "error");
        setLoadingMails((prev) => ({ ...prev, [erpInvoiceRef]: false }));
        return false;
      }

    } else {
      console.error(response.resMessage);
      setLoadingMails((prev) => ({ ...prev, [erpInvoiceRef]: false }));
      notify(response.resMessage, "error")
      return false;
    }

  };

  const onSelectionChanged = (e) => {
    setSelectedRows(e.selectedRowsData);
  };

  const [previewVisible, setPreviewVisible] = useState(false)
  const [xmlData, setXmlData] = useState(null);

  function onHidingPreview() {
    setXmlData(null)
    setPreviewVisible(!previewVisible);
  }

  const previewInvoice = async (erpInvoiceRef) => {
    try {
      const response = await getInvoice(erpInvoiceRef).finally(()=>{
        setLoadingPreviews((prev) => ({ ...prev, [erpInvoiceRef]: false }));

      });

      if (response && response.resCode == 200) {
        onHidingPreview();
        setXmlData(response)
      } else {
        console.error(response.resMessage);
        notify(response.resMessage, "error")
      }


    } catch (error) {
      console.error('PDF download error:', error);
      notify('PDF download error', "error")
    }
  }

  const handlePrint = () => {
    const renderedContent = document.querySelector(".flex.justify-center.p-0");

    if (renderedContent) {
      const iframe = document.createElement("iframe");
      iframe.style.position = "absolute";
      iframe.style.width = "0";
      iframe.style.height = "0";
      iframe.style.border = "none";

      document.body.appendChild(iframe);

      const doc = iframe.contentWindow?.document;
      if (doc) {
        doc.open();
        doc.write(`
                <!DOCTYPE html>
                <html>
                <head>
                  <style>
                    body {
                      font-family: Arial, sans-serif;
                      font-size: 10px;
                      margin: 5px;
                      line-height: 1.2;
                    }
                    table {
                      width: 100%;
                      border-collapse: collapse;
                    }
                    td, th {
                      padding: 2px;
                      border: 1px solid #ddd;
                    }
                    @media print {
                      @page {
                        size: A4;
                        margin: 10mm;
                      }
                    }
                  </style>
                </head>
                <body>
                  ${renderedContent.innerHTML}
                </body>
                </html>
              `);

        doc.close();

        iframe.contentWindow?.focus();
        iframe.contentWindow?.print();

        document.body.removeChild(iframe);
      }
    } else {
      console.error("No content found to print");
    }
  };

  const today = new Date();

// Başlangıç tarihi: Bugünden 10 gün öncesi
const pastDate = new Date(today);
pastDate.setDate(today.getDate() - 10); // Bugünden 10 gün önce

// Bitiş tarihi: Bugünün tarihi
const futureDate = new Date(today);

const bYear = pastDate.getFullYear();
const bMonth = String(pastDate.getMonth() + 1).padStart(2, '0');
const bDay = String(pastDate.getDate()).padStart(2, '0');

const eYear = futureDate.getFullYear();
const eMonth = String(futureDate.getMonth() + 1).padStart(2, '0');
const eDay = String(futureDate.getDate()).padStart(2, '0');

const bDate = `${bYear}-${bMonth}-${bDay}`;
const eDate = `${eYear}-${eMonth}-${eDay}`;

const [startDate, setStartDate] = useState(bDate);
const [endDate, setEndDate] = useState(eDate);

const formatDate2 = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${year}-${month}-${day}`;
};


  const [loadingPreviews, setLoadingPreviews] = useState<Record<string, boolean>>({});
  const [loadingXMLs, setLoadingXMLs] = useState<Record<string, boolean>>({});
  const [loadingPDFs, setLoadingPDFs] = useState<Record<string, boolean>>({});
  const [loadingMails, setLoadingMails] = useState<Record<string, boolean>>({});

  return (
    <div className="view crm-contact-list ">
      <div className="view-wrapper view-wrapper-contact-list list-page">
        <DataGrid
          className='grid theme-dependent'
          focusedRowEnabled
          dataSource={invoices}
          keyExpr="erpInvoiceRef"
          noDataText={t("noData")}
          onContentReady={handleContentReady}
          onSelectionChanged={onSelectionChanged}
          onExporting={onExporting}
          allowColumnReordering
          allowColumnResizing
          showBorders
          ref={gridRef}
        >

          <SearchPanel width={250} visible placeholder={t("customerSearch")} />
          <ColumnChooser title={t("columnChooser")} enabled />
          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Scrolling mode='virtual' />
          <Selection selectAllMode='allPages' showCheckBoxesMode='always' mode='multiple' />
          <FilterPanel visible={true} />
          <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />

          <Toolbar>

            <Item location='before' locateInMenu='auto'>
              <DropDownButton
                items={getTranslatedStatusList()}
                stylingMode='text'
                text={t(status)}
                useSelectMode
                width={100}
                onSelectionChanged={filterByStatus}
              />
            </Item>

            <Item name='searchPanel' location='before' />

            <Item location='before' locateInMenu='auto'>
              <DateBox defaultValue={startDate} onValueChanged={(e) => setStartDate(formatDate2(e.value))}
              displayFormat="dd.MM.yyyy" calendarOptions={{
                firstDayOfWeek: 1,
              }}/>
            </Item>

            <Item location='before' locateInMenu='auto'>
              <DateBox defaultValue={endDate} onValueChanged={(e) => setEndDate(formatDate2(e.value))}
              displayFormat="dd.MM.yyyy" calendarOptions={{
                firstDayOfWeek: 1,
              }}/>
            </Item>

            <Item
              location='before'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                hint={t("refresh")}
                stylingMode='text'
                onClick={getList}
              />
            </Item>

            <Item name='columnChooserButton' location='before' />

            <Item name='exportButton' location='before' />

            <Item location="before">
              <DropDownButton
                visible={selectedRows.length > 0}
                text={"(" + selectedRows.length + ") " + t("transactions")}
                icon="menu"
                width={"175"}
                items={[
                  //  { text: t("deleteSelected"), icon: 'trash', action: 'deleteSelected' },
                  { text: t("downloadXML"), icon: 'codeblock', action: 'downloadXML' },
                  { text: t("downloadPDF"), icon: 'pdffile', action: 'downloadPDF' },
                  { text: t("sendEmail"), icon: 'email', action: 'sendEmail' },
                ]}
                onItemClick={(e) => {
                  if (e.itemData.action == "deleteSelected") {
                    //    deleteSelected();
                  }
                  if (e.itemData.action == "downloadPDF") {
                    downloadSelectedPDF();
                  }
                  if (e.itemData.action == "downloadXML") {
                    downloadSelectedXML();
                  }
                  if (e.itemData.action == "sendEmail") {
                    sendEmailSelected();
                  }
                }}
              />
            </Item>

            <Item location='center' locateInMenu='auto'>
              {rowCount} {t("records")}
            </Item>



          </Toolbar>

          <Column dataField="eInvoiceStatus" width={150} hidingPriority={8} caption={t("status")} alignment="left"
            cellRender={(cell) => <>{cell.value == 0 ? <div className="text-yellow-500">{t("notSent")}</div> : (cell.value == 1 ? <div className="text-blue-400">{t("sent")}</div> : <div className="text-green-600">{t("approved")}</div>)}</>} />
          <Column dataField="erpInvoiceDate" caption={t("date")} hidingPriority={7} width={120} cellRender={(cell) => formatDateDMY(cell.value)} />
          <Column dataField="erpInvoiceNr" caption={t("invoiceNo")} hidingPriority={6} width={160} />
          <Column dataField="erpClientCode" caption={t("clientCode")} hidingPriority={6} width={150}  />
          <Column dataField="invoiceType" caption={t("invoiceType")} visible={false} hidingPriority={5}  />
          <Column dataField="erpClientName" caption={t("clientName")} hidingPriority={11} width={200} />
          <Column dataField="erpInvoiceTotal" caption={t("total")} hidingPriority={3} cellRender={(cell) => formatPriceAmount(cell.value)} />


          <Column caption={t("actions")} hidingPriority={9} alignment='center' width={160} cellRender={({ data }) => {

            const loadingPreview = loadingPreviews[data.erpInvoiceRef] || false;
            const loadingXML = loadingXMLs[data.erpInvoiceRef] || false;
            const loadingPDF = loadingPDFs[data.erpInvoiceRef] || false;
            const loadingMail = loadingMails[data.erpInvoiceRef] || false;

            return (
              <div className="flex items-center" >

                <button disabled={loadingPreview} title={t("preview")} onClick={() => {setLoadingPreviews((prev) => ({ ...prev, [data.erpInvoiceRef]: true })); previewInvoice(data.erpInvoiceRef);}} className="flex items-center justify-center hover:opacity-75">
                  <div className="flex px-0.5 justify-center items-center">{
                    loadingPreview ?
                      <FontAwesomeIcon className='animate-spin m-1 w-4 h-4' icon={faSpinner} /> :
                      <FontAwesomeIcon className='m-1 w-4 h-4 ' icon={faEye} />
                  }
                  </div>
                </button>

                <button disabled={loadingPDF} title={t("previewPDF")} onClick={() => {
                  setLoadingPDFs((prev) => ({ ...prev, [data.erpInvoiceRef]: true }));
                  openBase64PDFInNewTab(data.erpInvoiceNr).finally(()=>{setLoadingPDFs((prev) => ({ ...prev, [data.erpInvoiceRef]: false }))});
                }}
                  className="flex items-center justify-center hover:opacity-75">
                  <div className="flex px-0.5 justify-center items-center">{
                    loadingPDF ?
                      <FontAwesomeIcon className='animate-spin m-1 w-4 h-4' icon={faSpinner} /> :
                      <FontAwesomeIcon className='m-1 w-4 h-4 ' icon={faFilePdf} />
                  }
                  </div>
                </button>

                <button disabled={loadingXML} title={t("downloadXML")} onClick={() => {setLoadingXMLs((prev) => ({ ...prev, [data.erpInvoiceRef]: true })); handleXMLDownload(data.erpInvoiceRef);}} className="flex items-center justify-center hover:opacity-75">
                  <div className="flex px-0.5 justify-center items-center">{
                    loadingXML ?
                      <FontAwesomeIcon className='animate-spin m-1 w-4 h-4' icon={faSpinner} /> :
                      <FontAwesomeIcon className='m-1 w-4 h-4 ' icon={faFileImport} />
                  }
                  </div>
                </button>

                <button disabled={loadingMail} title={t("sendEmail")} onClick={() => {
                  setLoadingMails((prev) => ({ ...prev, [data.erpInvoiceRef]: true }));
                  handleEmailSend(data.erpInvoiceRef, data.arpLanguage).then((val)=>{
                    if(val){getList();}
                  });}}
                  className="flex items-center justify-center hover:opacity-75">
                  <div className="flex px-0.5 justify-center items-center">{
                    loadingMail ?
                      <FontAwesomeIcon className='animate-spin m-1 w-4 h-4' icon={faSpinner} /> :
                      <FontAwesomeIcon className='m-1 w-4 h-4 ' icon={faEnvelope} />
                  }
                  </div>
                </button>

              </div>
            )
          }} />

        </DataGrid>

        <Popup
          visible={previewVisible}
          onHiding={onHidingPreview}
          height={xmlData ? "95%" : 400}
          width={xmlData ? 900 : 600}
          hideOnOutsideClick
          titleRender={() => (
            <div className="flex flex-row justify-between">
              <div className="flex flex-row">
                <Button icon="close" stylingMode="text" onClick={onHidingPreview}></Button>
                <p className="ml-2 mt-0 justify-center font-bold text-lg">{t("preview")}</p>
              </div>

              <div className='flex flex-row gap-x-2'>
                <Button icon='print' stylingMode='contained' type='default' onClick={handlePrint} />
                <Button text={t("close")} icon='close' stylingMode='contained' type='default' style={{ backgroundColor: "gray" }} onClick={onHidingPreview} />
              </div>
            </div>
          )}
          contentRender={() =>
            <div className="flex justify-center p-0">
              {xmlData && invoicePreviewBody(xmlData, t)}
            </div>
          }
        ></Popup>



      </div>
    </div>
  );
}




const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === 'pdf') {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save('Campaigns.pdf');
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Page1');

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Campaigns.xlsx');
      });
    });
    e.cancel = true;
  }
};

const dropDownOptions = { width: 'auto',  };