import React, { useEffect, useRef, useState } from "react";
import { getEInvoiceKeyRequest, getInvoice, incomeInvoiceLookupCustomerRequest, IncomingInvoicesRequest, invoiceSendToApiRequest, xmlToJsonRequest, xmlToServer } from "../../../api/requests";
import notify from "devextreme/ui/notify";
import { Button, DataGrid, DateBox, DropDownButton, FileUploader, LoadPanel, Popup, ProgressBar, } from "devextreme-react";
import { useTranslation } from "react-i18next";
import { Column, ColumnChooser, DataGridRef, Toolbar, Export, FilterBuilderPopup, FilterPanel, HeaderFilter, Item, Scrolling, SearchPanel, Selection, Sorting } from "devextreme-react/cjs/data-grid";
import { appInfo } from "../../../app-info";
import { base64toPDFNewTab, } from "../../../utils/fileController";
import { formatDateDMY, formatPriceAmount } from "../../../utils/formats";
import invoicePreviewBody from "../../../utils/invoicePreviewDesign";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faSpinner, faTrash, faWarning } from "@fortawesome/free-solid-svg-icons";
import SelectSupplierPopup from "./components/selectSupplierPopup";
import { base64Encode } from "../../../utils/base64-decoder";
import { getVendorId } from "../../../api/sessions";
import axios from "axios";
import { kosit_validator } from "../../../utils/kosit-validator";

export const IncomingInvoices = () => {

  const gridRef = useRef<DataGridRef>(null);
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [importVisible, setImportVisible] = useState(false)
  const [previewVisible, setPreviewVisible] = useState(false)
  const [progressVisible, setProgressVisible] = useState(false);
  const [progressValue, setProgressValue] = useState(5);
  const [isDropZoneActive, setIsDropZoneActive] = useState(false);
  const [xmlValidating, setXmlValidating] = useState(0);
  const [xmlValidateLink, setXmlValidateLink] = useState("");
  const [xmlData, setXmlData] = useState(null);
  const [xmlContentString, setXmlContentString] = useState("");

  const [loadingSituation, setLoadingSituation] = useState("Dosya karşıya yükleniyor...")

  function onHidingImport() {
    setXmlData(null)
    setSaveButtonDisable(false);
    setSelectedSupplier(null)
    setXmlContentString("");
    setXmlValidating(0)
    setXmlValidateLink("")
    setImportVisible(!importVisible);
    setProgressValue(5)
    setLoadingSituation("Dosya karşıya yükleniyor...")
    setProgressVisible(false)
  }

  function clearImport(){
    setXmlData(null)
    setSaveButtonDisable(false);
    setSelectedSupplier(null)
    setXmlContentString("");
    setXmlValidating(0)
    setXmlValidateLink("")
    setProgressValue(5)
    setLoadingSituation("Dosya karşıya yükleniyor...")
    setProgressVisible(false)
  }

  function onHidingPreview() {
    setXmlData(null)
    setPreviewVisible(!previewVisible);
  }

  function getList() {
    gridRef.current?.instance().beginCustomLoading(t("loading"));
    incomeInvoiceLookupCustomerRequest().then((resp) => {
      if (resp.resCode == 200) {
        setSuppliersList(resp.supplierNames);
      } else {
        notify(resp.resMessage, "error");
      }
    });
    IncomingInvoicesRequest(startDate, endDate).then((res) => {
      if (res.resCode == 200) {
        setInvoices(res.dataset || []);
      } else {
        notify(res.resMessage, "error");
      }
      gridRef.current?.instance().endCustomLoading();
    })
  }

  useEffect(() => {
   //   onHidingImport();
      getList();
  }, [])


  const handleContentReady = (e) => {
    setRowCount(e.component.totalCount());
  };

  const allowedFileExtensions = ['.xml'];

  const onDropZoneEnter = () => setIsDropZoneActive(true);
  const onDropZoneLeave = () => setIsDropZoneActive(false);

  // Yükleme Başladı
  const onUploadStarted = (e) => {
    setProgressVisible(true);
    setProgressValue(5);
    handleFileProcessing(e.file)
  };

  // Yükleme İlerleme
  const onProgress = (e: any) => {
    setProgressValue(e.bytesLoaded / e.bytesTotal * 100);
  };


  const handleFileProcessing = (file: File) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      if (event.target && event.target.result) {
        const xmlContent = event.target.result;

        if (typeof xmlContent === "string") {
          setXmlContentString(xmlContent);

          try {


            const json = await xmlToJsonRequest(xmlContent);
            const str = JSON.stringify(json);
            const jsn = JSON.parse(str);
            setXmlData(jsn);

            var vatid = jsn["supplierData"][0]["supplierVATID"];
            var companyid = jsn["supplierData"][0]["supplierID"];

            setLoadingSituation("E-Fatura doğrulanıyor...")
            setProgressValue(125);
            // Kosit Validator API çağrısı
            //  notify(t("sentence4"),"warning",3000);
            kosit_validator(file).then((validationResponse) => {
              if (validationResponse.resCode == 200) {
                setXmlValidating(1)
                setXmlValidateLink(validationResponse.reportUrl)
                notify(t("validationSuccess"), "success");
                setProgressValue(150);

                incomeInvoiceLookupCustomerRequest().then((resp) => {
                  if (resp.resCode === 200 && suppliersList) {
                    let matchingSupplier;

                    if (vatid && vatid.trim() !== "") {
                      matchingSupplier = suppliersList.find(
                        (supplier) => supplier["supplierVatId"] === vatid
                      );
                    }

                    if (!matchingSupplier && companyid && companyid.trim() !== "") {
                      matchingSupplier = suppliersList.find(
                        (supplier) => supplier["supplierCompanyId"] === companyid
                      );
                    }

                    if (matchingSupplier) {
                      setSelectedSupplier(matchingSupplier);
                      notify(t("sentence1"), "success");
                    } else {
                      notify(t("sentence2"), "error");
                    }
                  } else {
                    notify(resp.resMessage, "error");
                  }
                });

              } else {
                setXmlValidating(2)
                setXmlValidateLink(validationResponse.reportUrl)
                notify(t("validationFailed"), "error");
                setLoadingSituation("E-Fatura doğrulama işlemi başarısız!")
              }
            });





          } catch (error) {
            console.error("XML to JSON conversion error:", error);
            setLoadingSituation("Bir hata oluştu!")
          }

          //   setProgressVisible(false);
        }
      }
    };

    // Dosyayı metin olarak oku
    reader.readAsText(file);
  };

  const handleXMLfromLink = async (xmlLink: string) => {

    try {

      var response = await axios.post("https://pay.mobiserver.de:4240/get-xml-from-key", {
        xmlLink: xmlLink
      });

      const xmlContent = response.data; // XML içeriği

      if (typeof xmlContent === "string") {
        setXmlContentString(xmlContent);

        try {
          const json = await xmlToJsonRequest(xmlContent);
          const str = JSON.stringify(json);
          const jsn = JSON.parse(str);
          setXmlData(jsn);
          setPreviewVisible(true);

        } catch (error) {
          console.error("XML to JSON conversion error:", error);
          notify("Failed to process XML data.", "error");
        }

        //  setProgressVisible(false);
      }
    } catch (error) {
      console.error("Error fetching XML file:", error);
      notify("An error occurred while fetching the XML file.", "error");
    }
  };


  const handlePrint = () => {
    const renderedContent = document.querySelector(".flex.justify-center.p-0");

    if (renderedContent) {
      const iframe = document.createElement("iframe");
      iframe.style.position = "absolute";
      iframe.style.width = "0";
      iframe.style.height = "0";
      iframe.style.border = "none";

      document.body.appendChild(iframe);

      const doc = iframe.contentWindow?.document;
      if (doc) {
        doc.open();
        doc.write(`
            <!DOCTYPE html>
            <html>
            <head>
              <style>
                body {
                  font-family: Arial, sans-serif;
                  font-size: 10px;
                  margin: 5px;
                  line-height: 1.2;
                }
                table {
                  width: 100%;
                  border-collapse: collapse;
                }
                td, th {
                  padding: 2px;
                  border: 1px solid #ddd;
                }
                @media print {
                  @page {
                    size: A4;
                    margin: 10mm;
                  }
                }
              </style>
            </head>
            <body>
              ${renderedContent.innerHTML}
            </body>
            </html>
          `);

        doc.close();

        iframe.contentWindow?.focus();
        iframe.contentWindow?.print();

        document.body.removeChild(iframe);
      }
    } else {
      console.error("No content found to print");
    }
  };


  const today = new Date();

  // Başlangıç tarihi: Bugünden 10 gün öncesi
  const pastDate = new Date(today);
  pastDate.setDate(today.getDate() - 10); // Bugünden 10 gün önce

  // Bitiş tarihi: Bugünün tarihi
  const futureDate = new Date(today);

  const bYear = pastDate.getFullYear();
  const bMonth = String(pastDate.getMonth() + 1).padStart(2, '0');
  const bDay = String(pastDate.getDate()).padStart(2, '0');

  const eYear = futureDate.getFullYear();
  const eMonth = String(futureDate.getMonth() + 1).padStart(2, '0');
  const eDay = String(futureDate.getDate()).padStart(2, '0');

  const bDate = `${bYear}-${bMonth}-${bDay}`;
  const eDate = `${eYear}-${eMonth}-${eDay}`;

  const [startDate, setStartDate] = useState(bDate);
  const [endDate, setEndDate] = useState(eDate);

  const formatDate2 = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${year}-${month}-${day}`;
  };




  const [suppliersList, setSuppliersList] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState<null | undefined | string>(undefined);

  const [saveButtonDisable, setSaveButtonDisable] = useState(false);

  const [openSelectSupplierPopup, setOpenSelectSupplierPopup] = useState(false);

  function onHidingSelectSupplierPopup() {
    setOpenSelectSupplierPopup(!openSelectSupplierPopup);
  }

  const [loadingPreviews, setLoadingPreviews] = useState<Record<string, boolean>>({});

  function handlePreview(eInvoiceRef) {
    setLoadingPreviews((prev) => ({ ...prev, [eInvoiceRef]: true }));
    getEInvoiceKeyRequest(eInvoiceRef).then((eInvoiceKeyReq) => {
      const xmlLink = "https://docs.mobioffice.de/einvoices/inbox/xml/" + base64Encode(getVendorId()) + "/" + eInvoiceKeyReq["datasource"][0]["eInvoiceKey"] + ".xml";
      handleXMLfromLink(xmlLink).finally(() => { setLoadingPreviews((prev) => ({ ...prev, [eInvoiceRef]: false })); });
    });

  }

  return (
    <div className="view crm-contact-list">
      <div className="view-wrapper view-wrapper-contact-list list-page">
        <DataGrid
          className='grid theme-dependent'
          focusedRowEnabled
          dataSource={invoices}
          keyExpr="eInvoiceRef"
          noDataText={t("noData")}
          onContentReady={handleContentReady}
          allowColumnReordering
          allowColumnResizing
          showBorders
          ref={gridRef}
        >


          <LoadPanel />
          <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Scrolling mode='virtual' />
          <FilterPanel visible={true} />
          <ColumnChooser title={t("columnChooser")} enabled />
          <SearchPanel width={250} visible placeholder={t("customerSearch")} />
          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />
          <Selection selectAllMode='allPages' showCheckBoxesMode='always' mode='multiple' />

          <Toolbar>

            <Item name='searchPanel' location='before' />

            <Item location='before' locateInMenu='auto'>
              <DateBox defaultValue={startDate} onValueChanged={(e) => setStartDate(formatDate2(e.value))}
              displayFormat="dd.MM.yyyy" calendarOptions={{
                firstDayOfWeek: 1,
              }} />
            </Item>

            <Item location='before' locateInMenu='auto'>
              <DateBox  defaultValue={endDate} onValueChanged={(e) => setEndDate(formatDate2(e.value))}
              displayFormat="dd.MM.yyyy" calendarOptions={{
                        firstDayOfWeek: 1,
                      }} />
            </Item>
            <Item

              location='before'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                hint={t("refresh")}
                stylingMode='text'
                onClick={getList}
              />
            </Item>

            <Item name='columnChooserButton' location='before' />

            <Item name='exportButton' location='before' />

            <Item location="before">
              <DropDownButton
                visible={selectedRows.length > 0}
                text={"(" + selectedRows.length + ") " + t("transactions")}
                icon="menu"
                width={"175"}
                items={[
                  { text: t("deleteSelected"), icon: 'trash', action: 'deleteSelected' },
                ]}
                onItemClick={(e) => {
                  if (e.itemData.action == "deleteSelected") {
                    //    deleteSelected();
                  }
                }}
              />
            </Item>

            <Item location='center' locateInMenu='auto'>
              {rowCount} {t("records")}
            </Item>

            <Item location='after' >
              <Button
                icon="import"
                type="default"
                hint={t("importFolder")}
                stylingMode="contained"
                onClick={onHidingImport}
              />
            </Item>



          </Toolbar>


          <Column dataField="erpInvoiceDate" caption={t("date")} hidingPriority={7} width={120} cellRender={(cell) => formatDateDMY(cell.value)} />
          <Column dataField="erpInvoiceNr" caption={t("invoiceNo")} hidingPriority={8} width={180} />
          <Column dataField="erpClientCode" caption={t("supplierCode")} hidingPriority={8} width={150} />
          <Column dataField="erpClientName" caption={t("supplierName")} hidingPriority={10} width={200} />
          <Column dataField="erpInvoiceTotal" caption={t("total")} hidingPriority={5}  cellRender={(cell) => formatPriceAmount(cell.value)} />
          <Column hidingPriority={9} alignment='center' width={80} cellRender={({ data }) => {

            const loadingPreview = loadingPreviews[data.eInvoiceRef] || false;

            return (
              <div className="flex gap-2 items-center">
                <button title={t("preview")} onClick={() => { handlePreview(data.eInvoiceRef) }}
                  className="flex items-center justify-center hover:opacity-75">
                  <div className="flex px-0.5 justify-center items-center">{
                    loadingPreview ?
                      <FontAwesomeIcon className='animate-spin m-1 w-4 h-4' icon={faSpinner} /> :
                      <FontAwesomeIcon className='m-1 w-4 h-4 ' icon={faEye} />
                  }
                  </div>
                </button>
              </div>
            );
          }} />

        </DataGrid>

        <Popup
          visible={importVisible}
          onHiding={onHidingImport}
          height={(xmlData && (xmlValidating == 1)) ? "90%" : 400}
          width={(xmlData && (xmlValidating == 1)) ? 900 : 600}
          titleRender={() => (
            <div className="flex flex-row justify-between">
              <div className="flex flex-row">
                <Button icon="close" stylingMode="text" onClick={onHidingImport}></Button>
                <p className="ml-2 mt-0 justify-center font-bold text-lg">{t("importEinvoice")}</p>
              </div>

              <div className='flex flex-row gap-x-2'>
                <div className="">
                  <Button icon='pdffile' visible={xmlData != null && (xmlValidating != 0) && xmlData["fileBase64"] != null && xmlData["fileBase64"] != ""} text={t("viewPDF")} stylingMode='contained' type='success' onClick={() => base64toPDFNewTab(xmlData ? xmlData["fileBase64"] : "")} />
                </div>


                <Button icon='save' disabled={saveButtonDisable || selectedSupplier == null || xmlValidating != 1} visible={xmlContentString != "" && xmlContentString != null && (xmlValidating == 1)} text={t("save")} stylingMode='contained' type='default'
                  onClick={() => {
                    setSaveButtonDisable(true)
                    invoiceSendToApiRequest(
                      selectedSupplier && selectedSupplier["eInvoiceArpRef"],
                      xmlData && xmlData["invoiceGeneral"][0]["slipTypeCode"],
                      xmlData && xmlData["invoiceGeneral"][0]["issueDate"],
                      xmlData && xmlData["invoiceGeneral"][0]["dueDate"],
                      xmlData && xmlData["invoiceGeneral"][0]["invoiceID"],
                      xmlData && xmlData["legalMonetaryTotal"][0]["taxInclusiveAmount"]
                    ).then((resp) => {
                      if (resp.resCode == 200) {

                        xmlToServer(xmlData && xmlData["invoiceGeneral"][0]["invoiceID"], xmlContentString, resp["dataSet"][0]["eInvoiceKey"]).then((response) => {
                          if (response.resCode == 200) {
                            notify(t("successfull"), "success");
                            onHidingImport();
                          } else {
                            notify(response.resMessage, "error")
                          }
                          setSaveButtonDisable(false);
                          getList();
                        })

                      } else {
                        notify(resp.resMessage, "error");
                      }
                    })

                  }} />

                <Button text={t("close")} icon='close' stylingMode='contained' type='default' style={{ backgroundColor: "gray" }} onClick={onHidingImport} />

              </div>

            </div>
          )}
          contentRender={() =>
            <div className="relative h-full w-full">
              <div className="absolute flex justify-center h-full w-full">
                {(xmlValidating == 0) && (
                  <div
                    id="dropzone-external"
                    className={`widget-container flex border-2 border-dashed h-full w-full items-center ${isDropZoneActive ? "dropzone-active" : ""
                      }`}
                    style={{
                      backgroundColor: isDropZoneActive ? "#cccccc" : "",
                      borderColor: isDropZoneActive ? "#00796b" : "gray",
                    }}
                  >
                    <div className="flex-box w-full">
                      <div id="dropzone-text" className={`flex flex-col items-center gap-2 ${progressVisible ? "hidden" : ""}`}>
                        <span>{t("clickAndImport")}</span>
                        <span>{t("or")}</span>
                        <span>{t("dragAndDrop")}</span>
                      </div>

                      <div className="flex items-center w-full justify-center pt-4">
                        <ProgressBar
                          id="upload-progress"
                          min={0}
                          max={150}
                          width="30%"
                          showStatus={false}
                          visible={progressVisible}
                          value={progressValue}
                        ></ProgressBar>
                      </div>

                      <div id="dropzone-text" className={`flex flex-col items-center gap-2 ${progressVisible ? "" : "hidden"}`}>
                        <span>{loadingSituation}</span>
                      </div>
                    </div>

                    <FileUploader
                      id="file-uploader"
                      dialogTrigger="#dropzone-external"
                      dropZone="#dropzone-external"
                      multiple={false}
                      allowedFileExtensions={allowedFileExtensions}
                      uploadMode="instantly"
                      disabled={progressVisible}
                      visible={false}
                      onDropZoneEnter={onDropZoneEnter}
                      onDropZoneLeave={onDropZoneLeave}
                      onProgress={onProgress}
                      onUploadStarted={onUploadStarted}
                    ></FileUploader>
                  </div>
                )}

                {(xmlValidating == 2) && (
                  <div
                    id="dropzone-external"
                    className={`widget-container flex h-full w-full items-center `}
                  style={{
                    backgroundColor: isDropZoneActive ? "#cccccc" : "",
                    borderColor: isDropZoneActive ? "#00796b" : "gray",
                  }}
                  >
                    <div className="flex-box w-full">

                      <div id="dropzone-text" className={`flex flex-col items-center gap-2 ${progressVisible ? "" : "hidden"}`}>
                        <button
                          onClick={() => {
                            if (xmlValidateLink) {
                              window.open(xmlValidateLink, "_blank");
                            } else {
                              console.error("URL is not defined!");
                            }
                          }}
                          className={`flex items-center justify-center hover:opacity-75 border rounded-md p-4 mb-4 text-red-500 `}>
                          <div className="flex px-1 justify-center items-center">{
                            <FontAwesomeIcon className='m-1 w-4 h-4 ' icon={faWarning} />
                          }
                          </div> {t("showErrors")}
                        </button>

                        <span className="text-center">{loadingSituation}</span>


                        <button
                          onClick={() => {
                            clearImport();
                          }}
                          className={`flex items-center justify-center hover:opacity-75 rounded-md p-4 mb-4 text-blue-400 `}>
                          <div className="flex px-1 justify-center items-center">
                          </div> {t("clear")}
                        </button>
                      </div>
                    </div>

                  </div>
                )}

                <div className="flex flex-col">
                  {(xmlData && (xmlValidating == 1)) &&
                    <div
                      className={`flex justify-between text-center items-center shadow-md p-2 mb-2 rounded-sm
                ${selectedSupplier ? 'bg-green-300' : 'bg-red-300'}`}
                    >

                      <div>
                        {!selectedSupplier && <>
                          {t("sentence2")}</>}

                        {selectedSupplier && <>
                          {t("supplierName")}: {selectedSupplier["supplierName"]} - {selectedSupplier["supplierCode"]}
                        </>}

                      </div>

                      <div>
                        <Button
                          disabled={xmlValidating != 1}
                          icon="more"
                          type="default"
                          stylingMode="contained"
                          hint={t("selectProducts")}
                          onClick={onHidingSelectSupplierPopup}
                        />
                      </div>
                    </div>}
                  {(xmlData && (xmlValidating == 1)) && invoicePreviewBody(xmlData, t)}
                </div>

                <hr></hr>

              </div>
            </div>
          }
        ></Popup>


        <SelectSupplierPopup visible={openSelectSupplierPopup} onHidingg={onHidingSelectSupplierPopup} setSelectedSupplier={setSelectedSupplier} />

        <Popup
          visible={previewVisible}
          onHiding={onHidingPreview}
          height={xmlData ? "90%" : 400}
          width={xmlData ? 900 : 600}
          hideOnOutsideClick
          titleRender={() => (
            <div className="flex flex-row justify-between">
              <div className="flex flex-row">
                <Button icon="close" stylingMode="text" onClick={onHidingPreview}></Button>
                <p className="ml-2 mt-0 justify-center font-bold text-lg">{t("preview")}</p>
              </div>

              <div className='flex flex-row gap-x-2'>
                <div className="">
                  <Button icon='pdffile' visible={xmlData != null && xmlData["fileBase64"] != null && xmlData["fileBase64"] != ""} text={t("viewPDF")} stylingMode='contained' type='success' onClick={() => base64toPDFNewTab(xmlData ? xmlData["fileBase64"] : "")} />
                </div>
                <Button icon='print' stylingMode='contained' type='default' onClick={handlePrint} />
                <Button text={t("close")} icon='close' stylingMode='contained' type='default' style={{ backgroundColor: "gray" }} onClick={onHidingPreview} />
              </div>
            </div>
          )}
          contentRender={() =>
            <div className="flex justify-center p-0">
              {xmlData && invoicePreviewBody(xmlData, t)}
            </div>
          }
        ></Popup>
      </div>
    </div>
  );
}