import 'jspdf-autotable';
import { base64Preview } from '../api/requests';



// Base64 stringini temizleyen yardımcı fonksiyon
function cleanBase64String(base64String) {
    return base64String
        .replace(/\r|\n/g, '')  // \r ve \n karakterlerini kaldır
        .replace(/\\\//g, '/'); // \/ karakterlerini / ile değiştir
}


export async function openBase64PDFInNewTab(invoiceNo) {
    try {
        // Fatura durumunu al
        const statusResponse = await base64Preview(143, invoiceNo);

        // Base64 stringini temizle
        const cleanedBase64 = cleanBase64String(statusResponse.fileBase64);

        // Base64 verisini çözümle
        const binaryString = atob(cleanedBase64);

        // Çözümlenen veriyi Uint8Array'e dönüştür
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        // Blob oluştur
        const blob = new Blob([bytes], { type: 'application/pdf' });

        // URL oluştur
        const url = URL.createObjectURL(blob);

        // Yeni sekmede aç
        window.open(url, '_blank');

        return true;
    } catch (error) {
        console.error('PDF açma hatası:', error);
        throw new Error('PDF açma hatası: ' + error);
    }
}

export async function downloadBase64PDF(invoiceNo) {
    try {
        // Fatura durumunu al
        const statusResponse = await base64Preview(143, invoiceNo);

        // Base64 stringini temizle
        const cleanedBase64 = cleanBase64String(statusResponse.fileBase64);

        // Base64 verisini çözümle
        const binaryString = atob(cleanedBase64);

        // Çözümlenen veriyi Uint8Array'e dönüştür
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        // Blob oluştur
        const blob = new Blob([bytes], { type: 'application/pdf' });

        // URL oluştur
        const url = URL.createObjectURL(blob);

        // İndirme linki oluştur ve tıklat
        const link = document.createElement('a');
        link.href = url;
        link.download = `${invoiceNo}.pdf`;
        document.body.appendChild(link);
        link.click();

        // Temizle
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        return true;
    } catch (error) {
        console.error('PDF indirme hatası:', error);
        throw new Error('PDF indirme hatası: ' + error);
    }
}

export async function base64toPDFNewTab(fileBase64) {
    try {
        // Base64 stringini temizle
        const cleanedBase64 = cleanBase64String(fileBase64);

        // Base64 verisini çözümle
        const binaryString = atob(cleanedBase64);

        // Çözümlenen veriyi Uint8Array'e dönüştür
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        // Blob oluştur
        const blob = new Blob([bytes], { type: 'application/pdf' });

        // URL oluştur
        const url = URL.createObjectURL(blob);

        // Yeni sekmede aç
        window.open(url, '_blank');

        return true;
    } catch (error) {
        console.error('PDF açma hatası:', error);
        throw new Error('PDF açma hatası: ' + error);
    }
}
