import { appInfo } from "./app-info"

export const getNavigation = (t) => {
  if (appInfo().menu == "akar") {
     return [
      {
        text: t('analytics'),
        icon: 'chart',
        path: '',
        items: [
          {
            text: t('dashboard'),
            path: '/analytics-dashboard',
          },

        ],
      },
      {
        text: t('materialManagement'),
        icon: 'ordersbox',
        path: '',
        items: [
          {
            text: t('productImages'),
            path: '/general-images',
          },
        ],
      },
      {
        text: t('sales'),
        icon: 'money',
        path: '',
        items: [
          {
            text: t('campaignGroups'),
            path: '/general-campaign-groups',
          },
          {
            text: t('campaigns'),
            path: '/general-campaigns',
          },
          {
            text: t('segmentation'),
            path: '/general-segmentations',
          },
          {
            text: t('incentives'),
            path: '/general-incentives',
          },
          {
            text: t('newProducts'),
            path: '/general-new-products',
          },


        ],
      },
      {
        text: t('fieldSales'),
        icon: 'globe',
        path: '',
        items: [

          {
            text: t('salesPersons'),
            path: '/sales-persons',
          },

          {
            text: t('routes'),
            path: '/sales-routes',
          },


        ],
      },
      {
        text: t("e-invoice"),
        icon: 'export',
        path: '',
        items: [

          {
            text: t('incomingInvoices'),
            path: '/incoming-invoices',
          },

          {
            text: t('outgoingInvoices'),
            path: '/outgoing-invoices',
          },

          {
            text: t('customerParameters'),
            path: '/customer-parameters',
          },

          {
            text: t('supplierParameters'),
            path: '/supplier-parameters',
          },

        ],
      },
      {
        text: t('settings'),
        icon: 'optionsgear',
        path: '',
        items: [
          {
            text: t('eInvoiceParams'),
            path: '/our-parameters',
          },

          {
            text: t('emailParams'),
            path: '/email-settings',
          },

          {
            text: t('ftpParams'),
            path: '/ftp-settings',
          },

        ],
      },
    ];
  } else
    if (appInfo().menu == "beta") {
      return [
        {
          text: t('analytics'),
          icon: 'chart',
          path: '',
          items: [
            {
              text: t('dashboard'),
              path: '/analytics-dashboard',
            },

          ],
        },
        {
          text: t('materialManagement'),
          icon: 'ordersbox',
          path: '',
          items: [
            {
              text: t('productImages'),
              path: '/general-images',
            },
          ],
        },
        {
          text: t('sales'),
          icon: 'money',
          path: '',
          items: [
            {
              text: t('campaignGroups'),
              path: '/general-campaign-groups',
            },
            {
              text: t('campaigns'),
              path: '/general-campaigns',
            },
            {
              text: t('segmentation'),
              path: '/general-segmentations',
            },
            {
              text: t('incentives'),
              path: '/general-incentives',
            },
            {
              text: t('newProducts'),
              path: '/general-new-products',
            },


          ],
        },
        {
          text: t('fieldSales'),
          icon: 'globe',
          path: '',
          items: [

            {
              text: t('salesPersons'),
              path: '/sales-persons',
            },

            {
              text: t('routes'),
              path: '/sales-routes',
            },


          ],
        },
        {
          text: 'E-Commerce',
          icon: 'cart',
          path: '',
          items: [

            {
              text: t('customerGroups'),
              path: '/b2b-customer-groups',
            },
            {
              text: t('customers'),
              path: '/b2b-customer-list',
            },
            {
              text: t('users'),
              path: '/b2b-customer-users',
            },
            {
              text: t('userRegister'),
              path: '/b2b-registrations',
            },

          ],
        },
        {
          text: t("e-invoice"),
          icon: 'export',
          path: '',
          items: [

            {
              text: t('incomingInvoices'),
              path: '/incoming-invoices',
            },

            {
              text: t('outgoingInvoices'),
              path: '/outgoing-invoices',
            },

            {
              text: t('customerParameters'),
              path: '/customer-parameters',
            },

            {
              text: t('supplierParameters'),
              path: '/supplier-parameters',
            },




          ],
        },
        {
          text: t('settings'),
          icon: 'optionsgear',
          path: '',
          items: [
            {
              text: t('eInvoiceParams'),
              path: '/our-parameters',
            },

            {
              text: t('emailParams'),
              path: '/email-settings',
            },

            {
              text: t('ftpParams'),
              path: '/ftp-settings',
            },

          ],
        },
      ];
    }
    else {
      return [
        {
          text: t('analytics'),
          icon: 'chart',
          path: '',
          items: [
            {
              text: t('dashboard'),
              path: '/analytics-dashboard',
            },
            {
              text: t('salesReport'),
              path: '/analytics-sales-report',
            },
          ],
        },
        {
          text: t('materialManagement'),
          icon: 'ordersbox',
          path: '',
          items: [
            {
              text: t('productImages'),
              path: '/general-images',
            },
          ],
        },
        {
          text: t('sales'),
          icon: 'money',
          path: '',
          items: [
            {
              text: t('campaignGroups'),
              path: '/general-campaign-groups',
            },
            {
              text: t('campaigns'),
              path: '/general-campaigns',
            },
            {
              text: t('segmentation'),
              path: '/general-segmentations',
            },
            {
              text: t('incentives'),
              path: '/general-incentives',
            },
            {
              text: t('newProducts'),
              path: '/general-new-products',
            },


          ],
        },
        {
          text: t('fieldSales'),
          icon: 'globe',
          path: '',
          items: [

            {
              text: t('salesPersons'),
              path: '/sales-persons',
            },

            {
              text: t('routes'),
              path: '/sales-routes',
            },


          ],
        },
        {
          text: 'E-Commerce',
          icon: 'cart',
          path: '',
          items: [

            {
              text: t('customerGroups'),
              path: '/b2b-customer-groups',
            },
            {
              text: t('customers'),
              path: '/b2b-customer-list',
            },
            {
              text: t('users'),
              path: '/b2b-customer-users',
            },
            {
              text: t('userRegister'),
              path: '/b2b-registrations',
            },

          ],
        },
        {
          text: t("e-invoice"),
          icon: 'export',
          path: '',
          items: [

            {
              text: t('incomingInvoices'),
              path: '/incoming-invoices',
            },

            {
              text: t('outgoingInvoices'),
              path: '/outgoing-invoices',
            },

            {
              text: t('customerParameters'),
              path: '/customer-parameters',
            },

            {
              text: t('supplierParameters'),
              path: '/supplier-parameters',
            },

          ],
        },
        {
          text: t('settings'),
          icon: 'optionsgear',
          path: '',
          items: [

            {
              text: t('userRoles'),
              path: '/b2b-user-groups',
            },

            {
              text: t('eInvoiceParams'),
              path: '/our-parameters',
            },

            {
              text: t('emailParams'),
              path: '/email-settings',
            },

            {
              text: t('ftpSettings'),
              path: '/ftp-settings',
            },

          ],
        },

        {
          text: 'Finance',
          icon: 'money',
          path: '',
          items: [
            {
              text: t('Order Payments'),
              path: '/finance-transaction',
            },

            {
              text: t('Open Invoices'),
              path: '/finance-payments',
            },

            {
              text: t('Finapi'),
              path: '/finance-finapi',
            },

          ],
        },

        {
          text: t('exports'),
          icon: 'export',
          path: '',
          items: [
            {
              text: t('datev'),
              path: '/erp-exports',
            },

          ],
        },

      ];
    }
}
