import React, { useState, useEffect } from 'react';
import { Button, ButtonTypes } from 'devextreme-react/button';
import { ScrollView } from 'devextreme-react/scroll-view';
import { SegmentModel } from '../../../types/crm-contact';
import ValidationGroup from 'devextreme-react/validation-group';
import notify from 'devextreme/ui/notify';
import { useTranslation } from 'react-i18next';
import { CheckBox, SelectBox, TextBox } from 'devextreme-react';
import { generalSegmentationPostRequest } from '../../../api/requests';
import { newSegmentModel } from '../../../shared/constants';
import { customerSelectionTypes, productSelectionTypes } from '../../../shared/select-boxes';
import { status_options, status_selectbox } from '../../../shared/status-selectbox';

export const SegmentInfoUpdate = (
  { mainRefresh, segment: initialSegment , setSegmentProductListRefresh,readOnlyTrue, readOnlyFalse, setCustomersTab,setProductsTab,setBrandsTab,setProductGroupTab,
    readOnly, isNewRecord,changePanelOpened,isPanelOpened,openNewRow,rowDataUpdate } ) => {

  const [segment, setSegment] = useState(initialSegment);
  const [segmentState, setSegmentState] = useState(initialSegment);

  useEffect(() => {
    if(isNewRecord){
      setSegment(newSegmentModel);
    }else{
      setSegment(initialSegment)
      setSegmentState(initialSegment)
    }
    setSelectedStatus(segment.segmentStatus);
  }, [isPanelOpened]);


  function onCancelClick(){
    if(isNewRecord){
      changePanelOpened();
    }else{
      setSegment(segmentState)
      setSelectedStatus(segmentState.segmentStatus)
      readOnlyTrue();
    }
  }

  function onSaveClick () {

    generalSegmentationPostRequest(segment).then((res) => {
      if (res["resCode"] == 200) {
        notify(t("successfull"), "success");
        setSegmentState(segment)
        if(isNewRecord){
          changePanelOpened();
          openNewRow(res.dataSet[0]["segmentRef"])
        }else{

          if(segment.customerSelectionType == 1){
            setCustomersTab(false)
          }else{setCustomersTab(true)}

          if(segment.productSelectionType == 1){
            setProductsTab(false)
          }else{setProductsTab(true)}

          if(segment.brandSelectionType == 1){
            setBrandsTab(false)
          }else{setBrandsTab(true)}

          if(segment.productGroupSelectionType == 1){
            setProductGroupTab(false)
          }else{setProductGroupTab(true)}


          mainRefresh();
          readOnlyTrue();
        }
        rowDataUpdate(segment)
      } else {
        notify(res["resMessage"], "error");
      }
    });


  }

  const { t } = useTranslation();

  const updateField = (key) => (value) => {
    setSegment(prev => ({
      ...prev,
      [key]: value
    }));

  };

  const [selectedStatus, setSelectedStatus] = useState<number>(0);

  const statusChange = (e: any) => {
    setSelectedStatus(e.value);
    updateField('segmentStatus')(e.value);
  };


  return (
    <>
      <ScrollView className='panel-scroll'>
        <ValidationGroup>
          <div className='w-full'>
          <div className='flex justify-between'>
                <div className='flex '>
                <SelectBox
                      dataSource={status_options}
                      value={selectedStatus}
                      defaultValue={0}
                      displayExpr={(item) => t(item.label)}
                      valueExpr="value"
                      onValueChanged={statusChange}
                      itemRender={status_selectbox}
                      searchEnabled={false}
                      readOnly={readOnly}
                      />
                  </div>


                <div className='flex flex-row gap-x-2'>
                  <Button visible={readOnly} text={t("edit")} icon='edit' stylingMode='contained' type='default' onClick={readOnlyFalse} />
                  <Button visible={readOnly} text={t("close")} icon='close' stylingMode='contained' style={{backgroundColor:"gray"}} type='default' onClick={changePanelOpened} />
                  <Button visible={!readOnly} text={t("save")} icon='save' stylingMode='contained' type='default' onClick={onSaveClick} />
                  <Button visible={!readOnly} text={t("cancel")} icon='close' stylingMode='contained' style={{backgroundColor:"gray"}} type='default' onClick={onCancelClick} />
                </div>

              </div>

              <div className='flex flex-row gap-x-3'>

                <div className='p-4 mt-3 w-full rounded-md shadow-sm border flex flex-col border-gray-300 gap-4'>
                  <div >
                    <TextBox
                      label={t("segmentCode")}
                      value={segment.segmentCode}
                      readOnly={readOnly}
                      onValueChange={updateField('segmentCode')}
                    />
                  </div>

                  <div >
                    <TextBox
                      label={t("segmentName")}
                      value={segment.segmentName}
                      readOnly={readOnly}
                      onValueChange={updateField('segmentName')}
                    />
                  </div>
                </div>


                <div className='p-4 mt-3 w-full rounded-md shadow-sm border flex flex-col border-gray-300 gap-4' >
                  <div >
                    <TextBox
                      label={t("specCode1")}
                      value={segment.speCode1}
                      readOnly={readOnly}
                      onValueChange={updateField('speCode1')}
                    />
                  </div>

                  <div >
                    <TextBox
                      label={t("specCode2")}
                      value={segment.speCode2}
                      readOnly={readOnly}
                      onValueChange={updateField('speCode2')}
                    />
                  </div>
                </div>

                <div className='p-4 mt-3 w-full rounded-md shadow-sm border flex flex-col border-gray-300 gap-4' >

                <div className=''>
                    <CheckBox
                      value={segment.customerSelectionType == 1}
                      text={t("allCustomers")}
                      onValueChanged={(e) => {
                        updateField('customerSelectionType')(e.value ? 1 : 0);
                      }}
                      readOnly={readOnly}
                    />
                  </div>

                <div className=''>
                    <CheckBox
                      value={segment.productSelectionType == 1}
                      text={t("allProducts")}
                      onValueChanged={(e) => {
                        updateField('productSelectionType')(e.value ? 1 : 0);
                      }}
                      readOnly={readOnly}
                    />
                  </div>

                <div className=''>
                    <CheckBox
                      value={segment.productGroupSelectionType == 1}
                      text={t("allProductGroups")}
                      onValueChanged={(e) => {
                        updateField('productGroupSelectionType')(e.value ? 1 : 0);
                      }}
                      readOnly={readOnly}
                    />
                  </div>

                <div className=''>
                    <CheckBox
                      value={segment.brandSelectionType == 1}
                      text={t("allBrands")}
                      onValueChanged={(e) => {
                        updateField('brandSelectionType')(e.value ? 1 : 0);
                      }}
                      readOnly={readOnly}
                    />
                  </div>


                  <div hidden={true} >
                    <TextBox
                      label={t("comment")}
                      value={segment.comment}
                      readOnly={readOnly}
                      onValueChange={updateField('comment')}
                    />
                  </div>
                </div>


              </div>
            </div>

        </ValidationGroup>
      </ScrollView>
    </>
  );
};
