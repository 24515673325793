import React, { useState, useCallback, useEffect } from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import { Button, ButtonTypes } from 'devextreme-react/button';
import { ScrollView } from 'devextreme-react/scroll-view';
import { CampaignModel } from '../../../types/crm-contact';
import ValidationGroup from 'devextreme-react/validation-group';
import notify from 'devextreme/ui/notify';
import { campaignGroupsListRequest, generalCampaignPostRequest } from '../../../api/requests';
import { useTranslation } from 'react-i18next';
import { DateBox, SelectBox, TextBox } from 'devextreme-react';
import { customerSelectionTypes } from '../../../shared/select-boxes';
import { newCampaignModel } from '../../../shared/constants';
import { status_options, status_selectbox } from '../../../shared/status-selectbox';

export const CampaignInfoUpdate = (
  {changePanelOpened, mainRefresh, campaign: initialCampaign , setCampaignProductListRefresh,readOnlyTrue, readOnlyFalse, readOnly,isNewRecord,openNewRow,isPanelOpened,setCustomersTab }
) => {

  const [campaign, setCampaign] = useState(initialCampaign);
  const [campaignState, setCampaignState] = useState(initialCampaign);
  const [campaignGroups, setCampaignGroups] = useState();

  useEffect(() => {
    if(isPanelOpened){
      if(isNewRecord){
        setCampaign(newCampaignModel);
        const today = new Date();
        const week = new Date(today);
        week.setDate(today.getDate() + 7);
        const formatDate = (date) => {
          return date.toISOString().split('T')[0];
        };
        const todayDate = formatDate(today);
        const weekDate = formatDate(week);

        updateField("beginDate")(todayDate);
        updateField("endDate")(weekDate);
      }else{
        setCampaign(initialCampaign)
        setCampaignState(initialCampaign);
      }
    }
    setSelectedStatus(campaign.campaignStatus);
    campaignGroupsListRequest().then((res) => {
      const filteredGroups = res.dataSet?.filter((group) => group.groupStatus == 1);
      setCampaignGroups(filteredGroups??[]);
    });
  }, [isPanelOpened]);


  function onCancelClick(){
    if(isNewRecord){
      changePanelOpened();
    }else{
      readOnlyTrue();
      setCampaign(campaignState)
      statusChange({"value":campaignState.campaignStatus})
    }
  }

  function onSaveClick () {

    generalCampaignPostRequest(campaign).then((res) => {
      if (res["resCode"] == 200) {
        if(isNewRecord){
          changePanelOpened();
          openNewRow(res.dataSet[0]["campaignRef"]);
          setCampaignProductListRefresh(prev => prev + 1);
        }else{
          if(campaign.customerSelectionType == 1){
            setCustomersTab(false)
          }else{setCustomersTab(true)}
          mainRefresh();
          setCampaignProductListRefresh(prev => prev + 1);
          notify(t("updateSuccessfull"), "success");
        }
        setCampaignState(campaign)
      } else {
        notify(res["resMessage"], "error");
      }
    }).finally(()=>readOnlyTrue());
  }

  const { t } = useTranslation();

  const updateField = (key) => (value) => {
    setCampaign(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const [selectedStatus, setSelectedStatus] = useState<number>(0);

  const statusChange = (e: any) => {
    setSelectedStatus(e.value);
    updateField('campaignStatus')(e.value);
  };


  return (
    <>
      <ScrollView className='panel-scroll'>
        <ValidationGroup>
          <div className='w-full'>
            <div className='flex flex-col'>

            <div className='flex justify-between'>
                <div className='flex '>
                <SelectBox
                      dataSource={status_options}
                      value={selectedStatus}
                      defaultValue={0}
                      displayExpr={(item) => t(item.label)}
                      valueExpr="value"
                      onValueChanged={statusChange}
                      itemRender={status_selectbox}
                      searchEnabled={false}
                      readOnly={readOnly}
                      />
                  </div>


                <div className='flex flex-row gap-x-2'>

                  <Button visible={readOnly} text={t("edit")} icon='edit' stylingMode='contained' type='default' onClick={readOnlyFalse} />
                  <Button visible={readOnly} text={t("close")} icon='close' stylingMode='contained' style={{backgroundColor:"gray"}} type='default' onClick={changePanelOpened} />
                  <Button visible={!readOnly} text={t("save")} icon='save' stylingMode='contained' type='default' onClick={onSaveClick} />
                  <Button visible={!readOnly} text={t("cancel")} icon='close' stylingMode='contained' style={{backgroundColor:"gray"}} type='default' onClick={onCancelClick}/>
                </div>

              </div>

              </div>

              <div className='flex my-1 gap-x-3'>

                <div  className='p-4 justify-evenly mt-3 w-full rounded-md shadow-sm border flex flex-col border-gray-300 gap-4'>
                  <div className=''>
                    <TextBox
                      label={t("campaignCode")}
                      value={campaign.campaignCode}
                      readOnly={true}
                      onValueChange={updateField('campaignCode')}
                    />
                  </div>

                  <div className=''>
                    <TextBox
                      label={t("campaignName")}
                      value={campaign.campaignName}
                      readOnly={readOnly}
                      onValueChange={updateField('campaignName')}
                    />
                  </div>
                </div>

                <div  className='p-4 justify-evenly mt-3 w-full rounded-md shadow-sm border flex flex-col border-gray-300 gap-4'>
                  <div >
                    <DateBox
                      label={t("beginDate")}
                      value={campaign.beginDate}
                      onValueChanged={(e) => updateField('beginDate')(e.value)}
                      displayFormat="dd.MM.yyyy"
                      readOnly={readOnly}
                      calendarOptions={{
                        firstDayOfWeek: 1,
                      }}
                    />
                  </div>

                  <div >
                    <DateBox
                      label={t("endDate")}
                      value={campaign.endDate}
                      onValueChanged={(e) => updateField('endDate')(e.value)}
                      displayFormat="dd.MM.yyyy"
                      readOnly={readOnly}
                      calendarOptions={{
                        firstDayOfWeek: 1,
                      }}
                    />
                  </div>
                </div>

                <div  className='p-4 justify-evenly mt-3 w-full rounded-md shadow-sm border flex flex-col border-gray-300 gap-4'>
                  <div className=''>
                    <TextBox
                      label={t("specCode1")}
                      value={campaign.erpClSpeCode1}
                      readOnly={readOnly||(campaign.customerSelectionType==0)}
                      disabled={(campaign.customerSelectionType==0)}
                      onValueChange={updateField('erpClSpeCode1')}
                    />
                  </div>

                  <div className=''>
                    <TextBox
                      label={t("specCode2")}
                      value={campaign.erpClSpeCode2}
                      readOnly={readOnly}
                      onValueChange={updateField('erpClSpeCode2')}
                    />
                  </div>
                </div>

                <div  className='p-4 justify-start mt-3 w-full rounded-md shadow-sm border flex flex-col border-gray-300 gap-4'>

                <div className=''>
                    <TextBox
                      label={t("erpCostCode")}
                      value={campaign.erpCostCode}
                      readOnly={readOnly}
                      onValueChange={updateField('erpCostCode')}
                    />
                  </div>

                  <div >
                    <SelectBox
                    value={campaign.campaignGroupRef}
                    dataSource={campaignGroups}
                    onValueChange={updateField('campaignGroupRef')}
                    label={t("campaignGroups")}
                    displayExpr="groupName"
                    valueExpr="groupRef"
                    readOnly={readOnly}
                    >

                    </SelectBox>
                  </div>


                  <div hidden={true} className=''>
                    <TextBox
                      label={t("comment")}
                      value={campaign.comment}
                      readOnly={readOnly}
                      onValueChange={updateField('comment')}
                    />
                  </div>
                </div>

                <div  className='p-4 justify-evenly mt-3 w-full rounded-md shadow-sm border flex flex-col border-gray-300 gap-4'>
                  <div className=''>
                    <CheckBox
                      value={campaign.runManually == "1"}
                      text={t("runManually")}
                      onValueChanged={(e) => {
                        updateField('runManually')(e.value ? "1" : "0");
                      }}
                      readOnly={readOnly}
                    />
                  </div>

                  <div className=''>
                    <CheckBox
                      value={campaign.amountDepended == "1"}
                      text={t("amountDepended")}
                      onValueChanged={(e) => {
                        updateField('amountDepended')(e.value ? "1" : "0");
                      }}
                      readOnly={readOnly}
                    />
                  </div>

                  <div className=''>
                    <CheckBox
                      value={campaign.isDiscPercent == "1"}
                      text={t("isDiscPercent")}
                      onValueChanged={(e) => {
                        updateField('isDiscPercent')(e.value ? "1" : "0");
                      }}
                      readOnly={readOnly}
                    />
                  </div>

                  <div className=''>
                    <CheckBox
                      value={campaign.customerSelectionType == 1}
                      text={t("allCustomers")}
                      onValueChanged={(e) => {
                        updateField('customerSelectionType')(e.value ? 1 : 0);
                      }}
                      readOnly={readOnly}
                    />
                  </div>

                  <div hidden className=''>
                    <CheckBox
                      value={campaign.forCertainCustomers == "1"}
                      text={t("forCertainCustomers")}
                      onValueChanged={(e) => {
                        updateField('forCertainCustomers')(e.value ? "1" : "0");
                      }}
                      readOnly={readOnly}
                    />
                  </div>
                </div>
              </div>
            </div>

        </ValidationGroup>
      </ScrollView>
    </>
  );
};
