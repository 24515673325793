import React from 'react';

import {
  Chart, Series, CommonSeriesSettings, Legend, Export,
  ArgumentAxis,
  Size,
  ValueAxis,
  Label,
  Format,
} from 'devextreme-react/chart';
import { CardAnalytics } from '../../library/card-analytics/CardAnalytics';
import { useTranslation } from 'react-i18next';

const BarGraph=({salesData1,salesData2,index,title})=> {

    var salesData = [{}];
      salesData.pop()
      try{
        if(index==1){
            for (let i = 0; i < 7; i++) {
                salesData.push({
                    "day": showWeekday(i+1),
                    "prevRevTotal": salesData2[i] && salesData2[i]["prevRevTotal"]?salesData2[i]["prevRevTotal"]:0,
                    "currRevTotal": salesData1[i] && salesData1[i]["currRevTotal"]?salesData1[i]["currRevTotal"]:0,
                });
            }
        }else{
                salesData.push({
                    "day": showWeekday(salesData2[0]["weekdayNumber"]),
                    "prevRevTotal": salesData2[0] && salesData2[0]["prevRevTotal"]?salesData2[0]["prevRevTotal"]:0,
                    "currRevTotal": salesData1[0] && salesData1[0]["currRevTotal"]?salesData1[0]["currRevTotal"]:0,
            });

        }

      }catch(e){console.log(e)}

      function showWeekday(dayNumber: number): string | null {
        const {t} = useTranslation();
        const weekdays: string[] = ['', t("monday"), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday'),t('sunday')];

        if (dayNumber < 1 || dayNumber > 7) {
            return null; // Geçersiz gün numarası
        }

        return weekdays[dayNumber];
    }

    const now = new Date();
  return (
    <CardAnalytics title={title} contentClass='sales-by-category'>
    <Chart
      id="chart"
      palette="Soft"
      dataSource={salesData}
    >
      <CommonSeriesSettings
        argumentField="day"
        type="bar"
        ignoreEmptyPoints={true}
      />
      <Series valueField="prevRevTotal" name={salesData2[0]["aggregatedDate"].split("-")[0]} color={"#FF5733"} />
      <Series valueField="currRevTotal" name={salesData1[0]["aggregatedDate"].split("-")[0]} color={"#1E88E5"}/>
      <Legend verticalAlignment="bottom" horizontalAlignment="center" />
      <ArgumentAxis argumentType='string' />
      <Size height={270} />
      <ValueAxis>
        <Label>
          <Format type='currency' currency='EUR' />
        </Label>
      </ValueAxis>
      <ArgumentAxis
          allowDecimals={false}
          axisDivisionFactor={60}
        >
          <Label>
          <Format type='currency' currency='EUR' />
          </Label>
        </ArgumentAxis>
    </Chart>
    </CardAnalytics>
  );
}

export default BarGraph;