import React from 'react';

import type { SingleCardProps } from '../../../types';

import './CardAuth.scss';
import { LanguageSelect } from '../language/select';
import logo from "../../../assets/mobiOfficeLogo.png"


export const CardAuth = ({ title, description, children }: React.PropsWithChildren<SingleCardProps>) => {
  return (
    <div className='auth-card'>
      <div className='dx-card content'>
      <div><LanguageSelect/></div>
        <div className='header'>
          <div className='flex w-full text-center justify-center items-center'>
          <img src={logo} className='w-64'></img>
          </div>
          <div className='title mt-6 hidden'>{ title }</div>
          <div className='description'>{ description }</div>
        </div>
        {children}
      </div>
    </div>
  );
};
