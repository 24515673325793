import { Button, LoadIndicator, Popup, SelectBox, TextArea, TextBox } from "devextreme-react";
import TabPanel, { Item as TabPanelItem } from 'devextreme-react/tab-panel';
import { useTranslation } from "react-i18next";
import { RegistrationStatus } from "../../utils/contact-status/ContactStatus";
import { useEffect, useState } from "react";
import { registerConfirmation, registrantsUpdateRecordRequest } from "../../../api/requests";
import notify from "devextreme/ui/notify";
import { base64Decode, base64Encode } from "../../../utils/base64-decoder";
import { getVendorId } from "../../../api/sessions";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { Item } from "devextreme-react/cjs/data-grid";

export const RegistrantPopup = ({ rowData, options, visible, onHiding, refresh }) => {
  const { t } = useTranslation();
  const [registrant, setRegistrant] = useState(rowData);
  const [userState, setUserState] = useState(rowData);
  const [tradingGroupOptions,setTradingGroupOptions] = useState()
  const [payPlanCodeOptions, setPayPlanCodeOptions ] = useState()
  const [priceListOptions, setPriceListOptions ] = useState()
  const [selectedStatus, setSelectedStatus] = useState<number>(0);
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    setReadOnly(true)
    setSelectedTab(0)
    setRegistrant(rowData);
    setUserState(rowData);
    setTradingGroupOptions(options?.tradingGroupOptions)
    setPayPlanCodeOptions(options?.payPlanCodeOptions)
    setPriceListOptions(options?.priceListOptions)
  }, [visible])

  const statusOptions = [
    { value: 0, label: t("requested"), color: 'rgb(78, 78, 193)' },
    { value: 1, label: t("approved"), color: 'green' },
    { value: 2, label: t("rejected"), color: 'rgb(208, 6, 6)' },
  ];

  const handleChange = (e: any) => {
    setSelectedStatus(e.value);
    updateField("requestStatus")(e.value);
  };

  const statusRender = (data) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: data.color,
            marginRight: '8px',
          }}
        ></span>
        {data.label}
      </div>
    );
  };

  const tradingGroupRender = (data) => {
    return (<span><b>{data.groupCode} </b> {data.groupDefinition} </span>);
  };

  const payPlanCodeRender = (data) => {
    return (<span><b>{data.planCode} </b> {data.planDefinition} </span>);
  };

  const priceListRender = (data) => {
    return (<span><b>{data.priceListCode} </b> {data.priceListDefinition} </span>);
  };

  const updateField = (key) => (value) => {
    setRegistrant(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const updateFieldAndSave = async (key, value) => {
    await new Promise((resolve) => {
      setRegistrant((prev) => {
        resolve({ ...prev, [key]: value });
        return { ...prev, [key]: value };
      });
    }).then((a)=>{
      setTimeout(()=>{
        onSave();
      },2000)
    });
  };


  function onSave() {
    setSaveClicked(true)
    if(registrant.requestStatus == 1){
      registerConfirmation(registrant.requestRef).then((resp)=>{
        if(resp.resCode == 200){
          updateField("customerCode")(resp.dataSet[0]["customerCode"]);
          updateField("erpCustomerCode")(resp.dataSet[0]["erpCustomerCode"]);
          updateField("erpShippingAdrCode")(resp.dataSet[0]["erpShippingAdrCode"]);

          registrantsUpdateRecordRequest(registrant).then((response) => {
            if (response.resCode == 200) {
              setReadOnly(true);
              notify(t("successfull"), "success");
              refresh();
              setUserState(registrant)
            } else {
              notify(response.resMessage, "error");
            }
            setSaveClicked(false)
          })

        }else{
          setSaveClicked(false)
          notify(resp.resMessage,"error")
        }
      })
    }else{

      registrantsUpdateRecordRequest(registrant).then((response) => {
        if (response.resCode == 200) {
          setReadOnly(true);
          notify(t("successfull"), "success");
          refresh();
          setUserState(registrant)
        } else {
          notify(response.resMessage, "error");
        }
        setSaveClicked(false)
      })

    }


  }
  const [selectedTab, setSelectedTab] = useState(0);

  const vendorBase64 = base64Encode(getVendorId());

  async function upload_folders(file, fileName) {

    try {
        // FormData oluşturuyoruz (dosya yükleme için ideal bir yöntem)
        const formData = new FormData();
        formData.append("file", file); // Dosyayı ekliyoruz
        formData.append("fileName", fileName); // Dosya adını ekliyoruz
        formData.append("vendor", vendorBase64); // Vendor bilgisini ekliyoruz

        // Axios ile POST isteği
        const response = await axios.post("https://pay.mobiserver.de:4240/webshop-registrant", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        console.log("Upload başarılı:", response.data);
        return response.data; // Yanıtı döndürüyoruz
    } catch (error) {
        console.error("Upload sırasında hata:", error);
        throw error; // Hata durumunda hata fırlatıyoruz
    }
}


async function handleImageButtonClick  (field: string, itemCode: string)  {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = 'image/*,application/pdf';
  input.style.display = 'none';
  document.body.appendChild(input);
  input.click();
  input.addEventListener('change', async (event) => {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      const newFileName = `${itemCode}`;
      const newRenamedFile = new File([file], newFileName, { type: file.type });

      // Yükleme işlemi sadece dosya seçildikten sonra yapılır
      const extension = file.name.substring(file.name.lastIndexOf('.') + 1);
      upload_folders(newRenamedFile,itemCode).then(async(resp)=>{
        if(resp.resCode == 200){
          updateFieldAndSave(field,extension ?? "unknown")
        }
      });
    }
    document.body.removeChild(input);
  });
};

const [saveClicked, setSaveClicked] = useState(false);

  return (
    <Popup
      visible={visible}
      onHiding={onHiding}
      title={`${registrant?.shopUserEmail || 'Kayıt Bilgisi'}`}
      height={"95%"}
      minWidth={"60%"}
      maxWidth={1000}
      hideOnOutsideClick
      titleRender={() => (
        <div className="flex flex-row justify-between">
          <div className="flex flex-row">
            <Button icon="close" stylingMode="text" onClick={onHiding}></Button>
            <p className="ml-2 mt-0 justify-center font-bold text-lg">{`${registrant?.shopUserEmail || 'Kayıt Bilgisi'}`}</p>
          </div>

        </div>
      )}
      contentRender={() => (
        <div>
          <div className="flex flex-row justify-between">
            <div className="">
              <SelectBox
                dataSource={statusOptions}
                value={registrant?.requestStatus}
                defaultValue={0}
                displayExpr="label"
                valueExpr="value"
                onValueChanged={handleChange}
                itemRender={statusRender}
                searchEnabled={false}
                readOnly={readOnly}
              ></SelectBox>
            </div>



            <div className="flex flex-row gap-x-1">
              <Button
                text={t("edit")}
                icon='edit'
                stylingMode='contained'
                type='default'
                onClick={() => { setReadOnly(false) }}
                visible={readOnly && registrant?.requestStatus != 1}
              />
              <Button
                text={t("save")}
                icon='save'
                stylingMode='contained'
                type='default'
                visible={!readOnly}
                disabled={saveClicked}
                onClick={onSave}
              />


              <div className="hidden sm:block">
                <Button
                  text={t("cancel")}
                  icon='close'
                  stylingMode='contained'
                  style={{ backgroundColor: "gray" }}
                  type='default'
                  onClick={() => { setReadOnly(true); setRegistrant(userState) }}
                  visible={!readOnly}
                />
              </div>

              <div className="hidden sm:block">
                <Button
                  text={t("close")}
                  icon='close'
                  stylingMode='contained'
                  style={{ backgroundColor: "gray" }}
                  type='default'
                  onClick={onHiding}
                  visible={readOnly}
                />
              </div>
            </div>

          </div>

          <TabPanel
              selectedIndex={selectedTab}
              onSelectionChanged={(e) => {
                const selectedIndex = e.component.option('selectedIndex');
                if (typeof selectedIndex === 'number') {
                  setSelectedTab(selectedIndex);
                }
              }}
            >


              <TabPanelItem title={t("firmInfo")}>

              <div className="flex flex-col md:flex-row gap-2">

              <div className="p-5 my-2 w-full md:w-1/2 rounded-md shadow-sm border border-gray-300">
                <p className="font-bold py-1 ">{t("firmInfo")}</p>
                <hr className="p-1"></hr>
                <div className="py-1">
                  <TextBox
                    label={t("firmName")}
                    value={registrant?.customerName}
                    readOnly={readOnly}
                    onValueChange={updateField('customerName')}
                  />
                </div>

                <div className="py-1">
                  <TextBox
                    label={t("companyDesc")}
                    hint="Firm official name or owner name"
                    value={registrant?.companyDesc}
                    readOnly={readOnly}
                    onValueChange={updateField('companyDesc')}
                  />
                </div>

                <div className="grid grid-cols-2 gap-2 pt-2">
                  <div>
                    <TextBox
                      label={t("taxNumber")}
                      value={registrant?.customerTaxNr}
                      readOnly={readOnly}
                      onValueChange={updateField('customerTaxNr')}
                    />
                  </div>
                  <div>
                    <TextBox
                      label={t("vatNumber")}
                      value={registrant?.customerVATNr}
                      readOnly={readOnly}
                      onValueChange={updateField('customerVATNr')}
                    />
                  </div>
                </div>
              </div>

              <div className="p-5 my-2 w-full md:w-1/2 rounded-md gap-2 shadow-sm border border-gray-300">
                <p className="font-bold py-1">{t("userInfo")}</p>
                <hr className="p-1"></hr>

                <div className="grid grid-cols-2 gap-2 py-1">

                  <div>
                    <TextBox
                      label={t("name")}
                      value={registrant?.shopUserFirstname}
                      readOnly={readOnly}
                      onValueChange={updateField('shopUserFirstname')}
                    />
                  </div>

                  <div>
                    <TextBox
                      label={t("surname")}
                      value={registrant?.shopUserSurname}
                      readOnly={readOnly}
                      onValueChange={updateField('shopUserSurname')}
                    />
                  </div>

                </div>

                <div className="grid grid-cols-1 gap-2 pt-2">

                <div>
                    <TextBox
                      label={t("email")}
                      value={registrant?.shopUserEmail}
                      readOnly={readOnly}
                      onValueChange={updateField('shopUserEmail')}
                    />
                  </div>


                  <div>
                    <TextBox
                      label={t("phone")}
                      value={registrant?.shopUserPhone}
                      readOnly={readOnly}
                      onValueChange={updateField('shopUserPhone')}
                    />
                  </div>





                </div>
              </div>


              </div>


              <div className="flex flex-col md:flex-row gap-2">

              <div className="p-5 my-2 w-full md:w-1/2 rounded-md gap-2 shadow-sm border border-gray-300">
                <p className="font-bold py-1">{t("firmAddress")}</p>
                <hr className="p-1"></hr>
                <div className="">
                  <TextBox
                    label={t("address")}
                    value={registrant?.customerAddress}
                    readOnly={readOnly}
                    onValueChange={updateField('customerAddress')}
                  />
                </div>

                <div className="grid grid-cols-5 gap-2 pt-2">

                  <div className="col-span-2">
                    <TextBox
                      label={t("postCode")}
                      value={registrant?.customerPostCode}
                      readOnly={readOnly}
                      onValueChange={updateField('customerPostCode')}
                    />
                  </div>

                  <div className="col-span-3">
                    <TextBox
                      label={t("city")}
                      value={registrant?.customerCity}
                      readOnly={readOnly}
                      onValueChange={updateField('customerCity')}
                    />
                  </div>



                </div>

                <div className="grid grid-cols-5 gap-2 pt-2">

                  <div className="col-span-2">
                    <TextBox
                      label={t("countryCode")}
                      value={registrant?.customerCountryCode}
                      readOnly={readOnly}
                      onValueChange={updateField('customerCountryCode')}
                    />
                  </div>

                  <div className="col-span-3">
                    <TextBox
                      label={t("country")}
                      value={registrant?.customerCountry}
                      readOnly={readOnly}
                      onValueChange={updateField('customerCountry')}
                    />
                  </div>


                </div>

                <div className="grid grid-cols-1 gap-2 pt-2">
                  <div>
                    <TextBox
                      label={t("moreInfo")}
                      value={registrant?.customerAdrInfo}
                      readOnly={readOnly}
                      onValueChange={updateField('customerAdrInfo')}
                    />
                  </div>
                </div>
              </div>

              <div className="p-5 my-2 w-full md:w-1/2 rounded-md gap-2 shadow-sm border border-gray-300">
                <p className="font-bold py-1">{t("shippingAddress")}</p>
                <hr className="p-1"></hr>
                <div className="gap-2">
                <TextBox
                    label={t("shippingAdrTitle")}
                    value={registrant?.shippingAdrName}
                    readOnly={readOnly}
                    onValueChange={updateField('shippingAdrName')}
                  />

                  <TextBox
                    label={t("address")}
                    value={registrant?.shippingAddress}
                    readOnly={readOnly}
                    onValueChange={updateField('shippingAddress')}
                  />

                </div>

                <div className="grid grid-cols-5 gap-2 pt-2">

                  <div className="col-span-2">
                    <TextBox
                      label={t("postCode")}
                      value={registrant?.shippingPostCode}
                      readOnly={readOnly}
                      onValueChange={updateField('shippingPostCode')}
                    />
                  </div>

                  <div className="col-span-3">
                    <TextBox
                      label={t("city")}
                      value={registrant?.shippingCity}
                      readOnly={readOnly}
                      onValueChange={updateField('shippingCity')}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-5 gap-2 pt-2">
                  <div className="col-span-2">
                    <TextBox
                      label={t("countryCode")}
                      value={registrant?.shippingCountryCode}
                      readOnly={readOnly}
                      onValueChange={updateField('shippingCountryCode')}
                    />
                  </div>

                  <div className="col-span-3">
                    <TextBox
                      label={t("country")}
                      value={registrant?.shippingCountry}
                      readOnly={readOnly}
                      onValueChange={updateField('shippingCountry')}
                    />
                  </div>


                </div>

                <div className="grid grid-cols-1 gap-2 pt-2">
                  <div>
                    <TextBox
                      label={t("moreInfo")}
                      value={registrant?.shippingAdrInfo}
                      readOnly={readOnly}
                      onValueChange={updateField('shippingAdrInfo')}
                    />
                  </div>
                </div>

              </div>


            <div className="flex flex-col p-5 my-2 w-full md:w-1/2 rounded-md shadow-sm border border-gray-300">
              <p className="font-bold py-1">{t("documents")}</p>
              <hr className="p-1"></hr>

              <div className="flex flex-col gap-2">

                <div className={`flex justify-between`}>
                    <p>{t("businessLicense")}</p>

                    <div className="flex gap-1">
                      <Button
                      icon='eyeopen'
                      stylingMode='contained'
                      type='normal'
                      onClick={() => {
                        window.open(`https://docs.mobioffice.de/webshop-registrant/${vendorBase64}/${registrant["docKey1"]}`, "_blank");
                      }}
                      visible={(registrant?.docType1 != "empty")&&(registrant?.docKey1 != "")}
                    />
                      <Button
                      hint={t("importOrUpdate")}
                      icon='upload'
                      stylingMode='contained'
                      type='normal'
                      disabled={readOnly}
                      onClick={()=>handleImageButtonClick("docType1", registrant["docKey1"])}
                    />
                    </div>
                </div>

                <div className={`flex justify-between`}>
                  <p>{t("commercialRegister")}</p>
                  <div className="flex gap-1">
                    <Button
                      icon='eyeopen'
                      stylingMode='contained'
                      type='normal'
                      onClick={() => {
                        window.open(`https://docs.mobioffice.de/webshop-registrant/${vendorBase64}/${registrant["docKey2"]}`, "_blank");
                      }}
                      visible={(registrant?.docType2 != "empty")&&(registrant?.docKey2 != "")}
                    />

                    <Button
                      hint={t("importOrUpdate")}
                      icon='upload'
                      stylingMode='contained'
                      type='normal'
                      disabled={readOnly}
                      onClick={()=>handleImageButtonClick("docType2", registrant["docKey2"])}
                    />
                  </div>
                </div>

                <div className={`flex justify-between`}>
                    <p>{t("otherDocuments")}</p>
                    <div className="flex gap-1">
                      <Button
                        icon='eyeopen'
                        stylingMode='contained'
                        type='normal'
                        onClick={() => {
                          window.open(`https://docs.mobioffice.de/webshop-registrant/${vendorBase64}/${registrant["docKey3"]}`, "_blank");
                        }}
                        visible={(registrant?.docType3 != "empty")&&(registrant?.docKey3 != "")}
                      />

                      <Button
                        hint={t("importOrUpdate")}
                        icon='upload'
                        stylingMode='contained'
                        type='normal'
                        disabled={readOnly}
                        onClick={()=>handleImageButtonClick("docType3", registrant["docKey3"])}
                      />
                    </div>

                </div>

            </div>

            </div>




              </div>

              <div className="p-5 my-2 w-full mb-8 rounded-md gap-2 shadow-sm border border-gray-300">
                <p className="font-bold py-1">{t("comment")}</p>
                <hr className="p-1"></hr>
                <div className="gap-2">
                  <TextArea
                    value={registrant?.requestNote}
                    readOnly={readOnly}
                    onValueChange={updateField('requestNote')}
                    height="auto" // Otomatik yükseklik için
                  />
                </div>
              </div>



              </TabPanelItem>


              <TabPanelItem title={t("otherInfos")}>


          <div className="flex gap-2">
            <div className="p-5 my-2 w-full md:w-1/2 rounded-md gap-2 shadow-sm border border-gray-300">
              <p className="font-bold py-1">{t("otherInfos")}</p>
              <hr className="p-1"></hr>


              <div className="">
              <SelectBox
                dataSource={tradingGroupOptions}
                label={t("tradingGroup")}
                value={registrant?.tradingGroup}
                itemRender={tradingGroupRender}
                defaultValue={0}
                displayExpr="groupCode"
                valueExpr="groupCode"
                onValueChanged={(e)=>updateField("tradingGroup")(e.value)}
                searchEnabled={false}
                readOnly={readOnly}
              ></SelectBox>
            </div>

              <div className="">
              <SelectBox
                dataSource={payPlanCodeOptions}
                label={t("paymentPlan")}
                value={registrant?.payPlanCode}
                itemRender={payPlanCodeRender}
                defaultValue={0}
                displayExpr="planDefinition"
                valueExpr="planCode"
                onValueChanged={(e)=>updateField("payPlanCode")(e.value)}
                searchEnabled={false}
                readOnly={readOnly}
              ></SelectBox>
            </div>

              <div className="">
              <SelectBox
                dataSource={priceListOptions}
                label={t("priceList")}
                value={registrant?.priceList}
                itemRender={priceListRender}
                defaultValue={0}
                displayExpr="priceListCode"
                valueExpr="priceListCode"
                onValueChanged={(e)=>updateField("priceList")(e.value)}
                searchEnabled={false}
                readOnly={readOnly}
              ></SelectBox>
            </div>

            </div>
          </div>






              </TabPanelItem>

            </TabPanel>






        </div>
      )}
    />
  );
};
