import { Button, DataGrid, DropDownButton } from "devextreme-react";
import { Column, ColumnChooser, DataGridRef, DataGridTypes, Editing, Export, FilterBuilderPopup, FilterPanel, HeaderFilter, Item, LoadPanel, RowDragging, Scrolling, SearchPanel, Selection, Sorting, Toolbar } from "devextreme-react/cjs/data-grid";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import notify from "devextreme/ui/notify";
import { customerParametersRequest } from "../../../api/requests";
import { appInfo } from "../../../app-info";
import { CustomerParametersRowUpdatePopup } from "./components/row-update";



export const CustomerParameters = () => {
  const gridRef = useRef<DataGridRef>(null);
  const {t} = useTranslation();
  const [parameters, setParameters] = useState([]);
  const [rowData, setRowData] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);



  function getList(){
    gridRef.current?.instance().beginCustomLoading(t("loading"));
    customerParametersRequest().then((res)=>{
      if(res.resCode == 200){
        setParameters(res.dataSet || []);
      }else{
        notify(res.resMessage);
      }
      gridRef.current?.instance().endCustomLoading();
    })
  }

  useEffect(()=>{
    getList();
  },[])

  const [isPanelOpened, setPanelOpened] = useState(false);

  const changePanelOpened = useCallback(() => {
        setPanelOpened(!isPanelOpened);

}, [isPanelOpened]);

  const onRowClick = useCallback(({ data,rowIndex }: DataGridTypes.RowClickEvent) => {
    setRowData(data);
    setPanelOpened(true);

  }, []);



  const onSelectionChanged = (e) => {
    setSelectedRows(e.selectedRowsData);
};


const handleContentReady = (e) => {
  setRowCount(e.component.totalCount());
};


  return (
    <div className="view crm-contact-list">
      <div className="view-wrapper view-wrapper-contact-list list-page">
      <DataGrid
          className='grid theme-dependent'
          focusedRowEnabled
          dataSource={parameters}
          keyExpr="eInvoiceArpRef"
          onRowClick={onRowClick}
          onExporting={onExporting}
          onSelectionChanged={onSelectionChanged}
          noDataText={t("noData")}
          onContentReady={handleContentReady}
          allowColumnReordering
          allowColumnResizing
          showBorders
          ref={gridRef}
          >

          <LoadPanel />
          <SearchPanel width={250} visible placeholder={t("customerSearch")}  />
          <ColumnChooser title={t("columnChooser")} enabled />
          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Scrolling mode='virtual' />
          <Selection selectAllMode='allPages' showCheckBoxesMode='always' mode='multiple' />
          <FilterPanel visible={true} />
          <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />

          <Toolbar>


          <Item name='searchPanel' location='before'  />

          <Item
              location='before'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                hint={t("refresh")}
                stylingMode='text'
                onClick={getList}
              />
            </Item>

            <Item name='columnChooserButton' location='before' />

            <Item name='exportButton' location='before' />

            <Item location="before">
                        <DropDownButton
                            visible={selectedRows.length>0}
                            text={"("+ selectedRows.length+") "+ t("transactions")}
                            icon="menu"
                            width={"175"}
                            items={[
                            { text: t("deleteSelected"), icon: 'trash', action: 'deleteSelected' },
                            ]}
                            onItemClick={(e) => {
                            if(e.itemData.action=="deleteSelected"){
                               // deleteSelected();
                            }
                            }}
                        />
                        </Item>

                        <Item location='center' locateInMenu='auto'>
                        {rowCount} {t("records")}
                        </Item>

            <Item location='after' locateInMenu='auto'>
              <Button
                icon='plus'
                type='default'
                stylingMode='contained'
                visible={true}
              />
            </Item>

          </Toolbar>

          <Column dataField="arpCode" caption={t("clientCode")} width={180}></Column>
          <Column dataField="registrationName" caption={t("clientName")} ></Column>
          <Column dataField="arpVatId" caption={t("vatId")} width={200}></Column>
          <Column dataField="contactName" caption={t("contactName")} ></Column>
          <Column dataField="arpCountry" caption={t("country")} width={220}></Column>

        </DataGrid>

        <CustomerParametersRowUpdatePopup visible={isPanelOpened} onHiding={changePanelOpened} refresh={getList} customerParameter={rowData} ></CustomerParametersRowUpdatePopup>
      </div>
    </div>
  );
};

const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === 'pdf') {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save('NewProductsProducts.pdf');
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Page1');

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'NewProductsProducts.xlsx');
      });
    });
    e.cancel = true;
  }
};
