import TabPanel, { Item as TabPanelItem } from 'devextreme-react/tab-panel';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import notify from 'devextreme/ui/notify';
import { Button, DropDownBox, SelectBox, TextBox } from 'devextreme-react';
import { Eye, EyeOff } from 'react-feather';
import { validateEmail } from '../../utils/formats';
import { base64Decode, base64Encode } from '../../utils/base64-decoder';
import { getSettingJSONRequest, sendSettingJSONRequest, smtpTESTRequest } from '../../api/requests';


type EmailSettingsModel = {

  smtp_User?: string;
  smtp_Host?: string;
  smtp_Port?: string;
  smtp_Sendername?: string;
  smtp_Password?: string;
  smtp_Secure?: number;
  smtp_CC?: string;
  smtp_BCC?: string;
  smtp_firmName?: string;

};

 const emptyEmailSettings: EmailSettingsModel = {
    smtp_User: "",
    smtp_Host: "",
    smtp_Port: "",
    smtp_Sendername: "",
    smtp_Password: "",
    smtp_Secure: 0,
    smtp_CC: "",
    smtp_BCC: "",
    smtp_firmName: ""
}



export const EmailSettings = ({ }) => {

  const [selectedTab, setSelectedTab] = useState(0);


  const [emailSettings, setEmailSettings] = useState<EmailSettingsModel>()
  const [emailSettingsState, setEmailSettingsState] = useState<EmailSettingsModel>()
  const [readOnlyEmailSettings, setReadOnlyEmailSettings] = useState(true)

  const { t } = useTranslation()

  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const updateFieldEmail = (key) => (value) => {
    setEmailSettings(prev => ({
      ...prev,
      [key]: value
    }));
  };


  function getData() {
    getSettingJSONRequest(100,2).then((resp)=>{
        if(resp.resCode == 200){
            if(resp.dataSet&&resp.dataSet[0]&&resp.dataSet[0]["settingJson"]){
                try{
                    setEmailSettings(JSON.parse(resp.dataSet[0]["settingJson"]))
                    setEmailSettingsState(JSON.parse(resp.dataSet[0]["settingJson"]))
                }catch(e){
                    setEmailSettings(emptyEmailSettings)
                    setEmailSettingsState(emptyEmailSettings)
                }

            }
        }else{
            setEmailSettings(emptyEmailSettings)
            setEmailSettingsState(emptyEmailSettings)
            notify(resp.resMessage,"error")
        }
    })

  }



  function handleSaveEmailSettings() {
    const settingJson = JSON.stringify(emailSettings)
    sendSettingJSONRequest(100, 2, settingJson).then((resp)=>{
        if(resp.resCode == 200){
            notify(t("successfull"),"success")
            setReadOnlyEmailSettings(true)
            setEmailSettingsState(emailSettings)
        }else{
            notify(resp.resMessage,"error")
        }
    })
  }


  useEffect(() => {
    getData();
  }, [])

  const smtpPresets = {
    gmail: {
      smtp_Host: "smtp.gmail.com",
      smtp_Port: 465,
      smtp_Secure: 1, // SSL/TLS
    },
    outlook: {
      smtp_Host: "smtp.office365.com",
      smtp_Port: 587,
      smtp_Secure: 3, // STARTTLS
    },
    hotmail: {
      smtp_Host: "smtp.office365.com",
      smtp_Port: 587,
      smtp_Secure: 3, // STARTTLS
    },
    yahoo: {
      smtp_Host: "smtp.mail.yahoo.com",
      smtp_Port: 465,
      smtp_Secure: 1, // SSL/TLS
    },
    sendgrid: {
      smtp_Host: "smtp.sendgrid.net",
      smtp_Port: 587,
      smtp_Secure: 3, // STARTTLS
    },
    localweb: {
      smtp_Host: "smtp.localweb.com.br",
      smtp_Port: 587,
      smtp_Secure: 3, // STARTTLS
    },
    sparkpost: {
      smtp_Host: "smtp.sparkpostmail.com",
      smtp_Port: 587,
      smtp_Secure: 3, // STARTTLS
    },
    elasticemail: {
      smtp_Host: "smtp.elasticemail.com",
      smtp_Port: 587,
      smtp_Secure: 3, // STARTTLS
    },
    mail: {
      smtp_Host: "smtp.mail.com",
      smtp_Port: 587,
      smtp_Secure: 3, // STARTTLS
    },
  };

  const handlePresetChange = (presetKey) => {
    const preset = smtpPresets[presetKey];
    if (preset) {
      updateFieldEmail("smtp_Host")(preset.smtp_Host);
      updateFieldEmail("smtp_Port")(preset.smtp_Port);
      updateFieldEmail("smtp_Secure")(preset.smtp_Secure);
    }
  };

  const [testClicked, setTestClicked] = useState(false)
  function handleTestClick(){
    setTestClicked(true);
    smtpTESTRequest().then((resp)=>{
      if(resp.resCode == 200){
        notify(t("connectionSuccessfully"),"success")
      }else{
        notify(resp.resMessage,"error")
      }
      setTestClicked(false)
    })
  }

  return (
    <div className='view crm-contact-list h-full w-full'>
      <div className=' p-8 h-full w-full rounded-lg overflow-auto'>
        <div className=' p-5 bg-white  rounded-lg border border-gray-300 '>



          <div className=''>
            <TabPanel
              selectedIndex={selectedTab}
              onSelectionChanged={(e) => {
                const selectedIndex = e.component.option('selectedIndex');
                if (typeof selectedIndex === 'number') {
                  setSelectedTab(selectedIndex);
                }
              }}
            >

              <TabPanelItem title={t("e-invoice")}>
              <div className='flex'>

              <div className='flex flex-col md:flex-row w-full gap-4 mt-3'>

                  <div className="p-5 my-2 rounded-md flex flex-col gap-4 border shadow-md">
                    <div>
                      <b>{t("einvoiceSenderInfo")}</b>
                      <hr></hr>
                    </div>

                    <div className='flex flex-col  md:flex-row gap-8'>
                      <div className='flex flex-col w-72 gap-4'>
                        <TextBox
                          label={t("host")}
                          value={emailSettings?.smtp_Host}
                          readOnly={readOnlyEmailSettings}
                          onValueChange={updateFieldEmail('smtp_Host')}
                        />

                        <TextBox
                          label={t("email")}
                          hint={t("hint3")}
                          value={emailSettings?.smtp_User}
                          readOnly={readOnlyEmailSettings}
                          onValueChange={updateFieldEmail('smtp_User')}
                        />


                        <div className=''>
                          <div style={{ position: 'relative' }}>
                            <TextBox
                              className='relative'
                              label={t("password")}
                              value={base64Decode(emailSettings?.smtp_Password)}
                              mode={isPasswordVisible ? 'text' : 'password'}
                              inputAttr={{ autocomplete: 'new-password' }}
                              onValueChange={(e)=> updateFieldEmail('smtp_Password')(base64Encode(e))}
                              readOnly={readOnlyEmailSettings}
                            />
                            <div
                              onClick={togglePasswordVisibility}
                              style={{
                                position: 'absolute',
                                right: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer'
                              }}
                            >
                              {isPasswordVisible ? <EyeOff /> : <Eye />}
                            </div>
                          </div>
                        </div>

                        <TextBox
                          label={t("port")}
                          value={emailSettings?.smtp_Port}
                          readOnly={readOnlyEmailSettings}
                          onValueChange={updateFieldEmail('smtp_Port')}
                        />

                        <SelectBox
                          label={t("secure")}
                          value={emailSettings?.smtp_Secure ?? 0}
                          readOnly={readOnlyEmailSettings}
                          items={[
                            { value: 0, label: t("no") },
                            { value: 1, label: t("SSL/TLS") },
                            { value: 2, label: t("SSL/TLS (check certificate)") },
                            { value: 3, label: t("STARTTLS") },
                            { value: 4, label: t("STARTTLS (check certificate)") },
                          ]}
                          displayExpr="label"
                          valueExpr="value"
                          onValueChange={updateFieldEmail('smtp_Secure')}
                        />
                      </div>




                      <div className='flex flex-col w-72 gap-4'>
                        <TextBox
                          label={t("firmName")}
                          hint={t("hint1")}
                          value={emailSettings?.smtp_firmName}
                          readOnly={readOnlyEmailSettings}
                          onValueChange={updateFieldEmail('smtp_firmName')}
                        />

                        <TextBox
                          label={t("senderName")}
                          hint={t("hint2")}
                          value={emailSettings?.smtp_Sendername}
                          readOnly={readOnlyEmailSettings}
                          onValueChange={updateFieldEmail('smtp_Sendername')}
                        />

                        <TextBox
                          label={t("CC")}
                          value={emailSettings?.smtp_CC}
                          readOnly={readOnlyEmailSettings}
                          onValueChange={updateFieldEmail('smtp_CC')}

                        />

                        <TextBox
                          label={t("BCC")}
                          value={emailSettings?.smtp_BCC}
                          readOnly={readOnlyEmailSettings}
                          onValueChange={updateFieldEmail('smtp_BCC')}

                        />

                    <div className="flex flex-wrap gap-4">
                            {[
                              { value: "gmail", label: "Gmail" },
                              { value: "outlook", label: "Outlook/Office 365" },
                              { value: "hotmail", label: "Hotmail" },
                              { value: "yahoo", label: "Yahoo" },
                              { value: "sendgrid", label: "SendGrid" },
                              { value: "localweb", label: "LocalWeb" },
                              { value: "sparkpost", label: "SparkPost" },
                              { value: "elasticemail", label: "Elastic Email" },
                              { value: "mail", label: "Mail" },
                            ].map((provider) => (
                              <label key={provider.value} className="flex items-center gap-2">
                                <input
                                  disabled = {readOnlyEmailSettings}
                                  type="radio"
                                  name="smtpProvider"
                                  value={provider.value}
                                  onChange={() => handlePresetChange(provider.value)}
                                />
                                {provider.label}
                              </label>
                            ))}
                          </div>


                      </div>
                    </div>

                  </div>

                </div>


              <div className='flex justify-end mt-4 gap-2'>
              <Button
                visible={readOnlyEmailSettings}
                disabled={testClicked}
                icon='refresh'
                text={t("connectionTest")}
                onClick={handleTestClick} />
            <div className='flex flex-row gap-x-2'>
              <Button visible={readOnlyEmailSettings} text={t("edit")} icon='edit' stylingMode='contained' type='default' onClick={() => { setReadOnlyEmailSettings(false) }} />
              <Button visible={!readOnlyEmailSettings} text={t("save")} icon='save' stylingMode='contained' type='default' onClick={handleSaveEmailSettings} />
              <Button visible={!readOnlyEmailSettings} text={t("cancel")} icon='close' stylingMode='contained' style={{ backgroundColor: "gray" }} type='default'
               onClick={() => {
                 setReadOnlyEmailSettings(true)
                 setEmailSettings(emailSettingsState)
                 }} />
            </div>
          </div>
              </div>


              </TabPanelItem>


            </TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
};
















