import React, { useState, useRef, useCallback, useContext } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import Button, { ButtonTypes } from 'devextreme-react/button';
import Form, { Item, Label, ButtonItem, ButtonOptions, RequiredRule, EmailRule } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';

import { useAuth } from '../../../contexts/auth';
import { ThemeContext } from '../../../theme/theme';

import './LoginForm.scss';
import { getCustomerGroupsRequest, loginRequest } from '../../../api/requests';
import { customersToSession, setAppToken, setFirmName, setUserName, setVendorId } from '../../../api/sessions';
import { useTranslation } from 'react-i18next';
import { useCustomerContext } from '../../../contexts/customers';
import { appInfo } from '../../../app-info';
import ReCAPTCHA from "react-google-recaptcha";


function getButtonStylingMode(theme: string | undefined): ButtonTypes.ButtonStyle {
  return theme === 'dark' ? 'outlined' : 'contained';
}

export const LoginForm = ({ resetLink, createAccountLink }) => {
  const { t } = useTranslation();
  const [verified, setVerified] = useState(false);

    const onChange = (value) => {
      setVerified(true);
    };

  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ userName: '', vendorId: '', password: '' });
  const themeContext = useContext(ThemeContext);
  const { setCustomers } = useCustomerContext();

  const onSubmit = useCallback(async (e: any) => {
    e.preventDefault();
    if (!verified) {
      alert("Please verify that you are not a robot.");
      return;
    }

    const { userName, vendorId, password } = formData.current;
    setLoading(true);

    try {
      customersToSession("");
      const response =await loginRequest(userName,password,vendorId);

      if (response.data.resCode == 200) {
        var token = response.data.salesUsers[0]["token"];
        setAppToken(token);
        setVendorId(vendorId);
        setUserName(userName);
        setFirmName(response.data.salesUsers[0]["erpFirmName"]??"")

        if(appInfo().menu=="akar"){

        }else{
          getCustomerGroupsRequest();
        }

        const result = await signIn(userName, password,);
        notify(t("loginSuccessfull"), 'success', 1500);
        if (!result.isOk) {
          notify(result.message, 'error', 3000);
        }
      } else {
        notify(response.data.resMessage, 'error', 3000);
      }
    } catch (error) {
      notify('Bir hata oluştu. Lütfen tekrar deneyin.', 'error', 3000);
    } finally {
      setLoading(false);
    }
  }, [signIn,verified]);

  const onCreateAccountClick = useCallback(() => {
    navigate(createAccountLink);
  }, [navigate]);

  const userNameEditorOptions = { stylingMode: 'filled', placeholder: t('userName') };
  const vendorIdEditorOptions = { stylingMode: 'filled', placeholder:  t('vendorID') };
  const passwordEditorOptions = { stylingMode: 'filled', placeholder:  t('password'), mode: 'password' };
  const rememberMeEditorOptions = { text:  t('rememberMe'), elementAttr: { class: 'form-text' } };



  return (
    <form className='login-form' onSubmit={onSubmit}>
      <Form
        formData={formData.current}
        disabled={loading}
        showColonAfterLabel
        showRequiredMark={false}
      >
        <Item
          dataField={'vendorId'}
          editorType={'dxTextBox'}
          editorOptions={vendorIdEditorOptions}
        >
          <RequiredRule message="Vendor ID is required" />
          <Label visible={false} />
        </Item>
      <Item
          dataField={'userName'}
          editorType={'dxTextBox'}
          editorOptions={userNameEditorOptions}
        >
          <RequiredRule message="User Name is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item dataField='rememberMe' editorType='dxCheckBox' editorOptions={rememberMeEditorOptions}>
          <Label visible={false} />
        </Item>
        <Item >
          <ReCAPTCHA
            sitekey="6LfBMQsqAAAAAAcQ8BZ0gAgZ3Jaexg8WUf6KzCAH"
            onChange={onChange}
                />
        </Item>
        <ButtonItem>
          <ButtonOptions width='100%' type='default' useSubmitBehavior>
            <span className='dx-button-text'>{loading ? <LoadIndicator width='24px' height='24px' visible /> :t("signIn")}</span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
      <div className='reset-link hidden'>
        <Link to={resetLink}>{t('forgotPassword')}</Link>
      </div>

      <Button
        className='btn-create-account'
        text={t('createAccount')}
        width='100%'
        visible={false}
        onClick={onCreateAccountClick}
        stylingMode={getButtonStylingMode(themeContext?.theme)}
      />
      <p className='text-center text-[11px] mt-6'>{appInfo().version}</p>
    </form>
  );
};
