import { formatNumber } from 'devextreme/localization';

export const formatCurrency = (value: number) => formatNumber(value, { type: 'currency', currency:"EUR" });

export function euroFormat(amount: number): string {
    const formattedAmount = amount.toLocaleString('de-DE', { maximumFractionDigits: 0 });
    return `${formattedAmount} €`;
}

export function formatDateDMY(date: string): string {
    const parsedDate = new Date(date);
    const day = String(parsedDate.getDate()).padStart(2, '0');
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
    const year = parsedDate.getFullYear();

    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate;
}

export function formatPriceAmount(amount) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount);
  }

  export const validateEmail = (email): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basit e-posta doğrulama
    return emailRegex.test(email);
  };