
import React, { useEffect, useState } from 'react';
import { Button, Popup, SelectBox, TextBox } from 'devextreme-react';
import { useTranslation } from 'react-i18next';
import notify from 'devextreme/ui/notify';
import { parameterRequest } from '../../../../api/requests';
import { validateEmail } from '../../../../utils/formats';

export const CustomerParametersRowUpdatePopup = ({ visible, onHiding, refresh, customerParameter }) => {
  const [parameter, setParameter] = useState(customerParameter);
  const [parameterState, setParameterState] = useState(customerParameter);
  const { t } = useTranslation();

  useEffect(() => {
    setParameter(customerParameter);
    setParameterState(customerParameter);
    setReadOnly(true)
  }, [visible]);

  const updateField = (key) => (value) => {
    setParameter(prev => ({
      ...prev,
      [key]: value
    }));
  };

  function validator(parameter): boolean {
    if(parameter){
    return !!(
        parameter?.arpStreet &&
        parameter.arpCity &&
        parameter.arpPostCode &&
        parameter.arpCountryCode &&
        parameter.arpCountry &&
        parameter.arpEMail &&
        parameter.registrationName
    );}else{
      return true;
    }
}

  function onSaveClick() {

    if (!validator(parameter)) {
        notify(t("sentence3"), "error");
        return;
    }

    if(!validateEmail(parameter?.arpEMail)){
      notify("Email format is not valid!","error");
      return;
    }

    parameterRequest(parameter, 1).then((res) => {
      if (res.resCode == 200) {
        refresh();
        notify(t("successfull"), 'success');
        onHiding();
        setParameterState(parameter)

      } else {
        notify(res.resMessage, 'error');
      }
    });

  }

  function onCancelClick() {
    setReadOnly(true)
    setParameter(parameterState)
  }


const [readOnly, setReadOnly] = useState(true);

  return (
    <Popup
      visible={visible}
      onHiding={onHiding}
      width={1000}
      height={"auto"}
      maxHeight={"95%"}
      titleRender={() => (
        <div className="flex flex-row justify-between py-2">

          <div className="flex flex-row">
            <Button icon="close" stylingMode="text" onClick={onHiding}></Button>
            <p className="ml-2 mt-0.5 justify-center font-bold text-lg">{customerParameter.groupRef == 0 ? t("new") : customerParameter.registrationName}</p>
          </div>


          <div className='flex flex-row gap-x-2'>
            <Button visible={readOnly} text={t("edit")} icon='edit' stylingMode='contained' type='default' onClick={()=>setReadOnly(false)} />
            <Button visible={readOnly} text={t("close")} icon='close' stylingMode='contained' style={{backgroundColor:"gray"}} type='default' onClick={onHiding} />
            <Button visible={!readOnly} text={t("save")} icon='save' stylingMode='contained' type='default' onClick={onSaveClick} />
            <Button visible={!readOnly} text={t("cancel")} icon='close' stylingMode='contained' style={{backgroundColor:"gray"}} type='default' onClick={onCancelClick} />
          </div>

        </div>
      )}
      contentRender={() =>
      (
        <div className='flex flex-col gap-y-3 p-2'>
          <div className={`${ validator(parameter) ? "hidden" : "flex" } p-2 bg-red-200 shadow-sm`}>
            {t("sentence3")}
          </div>

          <div className='flex gap-4'>

            <div className='flex flex-col w-full gap-3 border shadow-md p-3'>
              <TextBox
                value={parameter?.arpCode}
                onValueChange={updateField('arpCode')}
                label={t("code")}
                readOnly= {readOnly}
                disabled
              />

              <TextBox
                label={t("street") + "*"}
                value={parameter?.arpStreet}
                onValueChange={updateField('arpStreet')}
                readOnly= {readOnly}
              />

              <TextBox
                value={parameter?.arpCity}
                onValueChange={updateField('arpCity')}
                label={t("city") + "*"}
                readOnly= {readOnly}
              />

              <TextBox
                value={parameter?.arpPostCode}
                onValueChange={updateField('arpPostCode')}
                label={t("postCode") + "*"}
                readOnly= {readOnly}
              />

              {
                /*
              <SelectBox
                                label={t("country")}
                                items={countryCodes}
                                value={parameter?.arpCountry}
                                onValueChanged={(e) => {
                                  const selectedCountry = countryCodes.find((item) => item.name === e.value);
                                  if (selectedCountry) {
                                    updateField("arpCountry")(selectedCountry.name);
                                    updateField("arpCountryCode")(selectedCountry.code);
                                  }
                                }}
                                valueExpr="name"
                                displayExpr="name"
                                placeholder={t("search")}
                                searchEnabled={true}
                              />
                */
              }


              <TextBox
                value={parameter?.arpCountry}
                onValueChange={updateField('arpCountry')}
                label={t("country") + "*"}
                readOnly= {readOnly}
              />

              <TextBox
                value={parameter?.arpCountryCode}
                onValueChange={updateField('arpCountryCode')}
                label={t("countryCode") + "*"}
                hint='Country code MUST be coded using ISO code list 3166-1'
                readOnly= {readOnly}
              />


            </div>

            <div className='flex flex-col w-full gap-3 border shadow-md p-3'>

              <TextBox
                value={parameter?.contactName}
                onValueChange={updateField('contactName')}
                label={t("contactName")}
                readOnly= {readOnly}
              />

              <TextBox
                value={parameter?.contactPhone}
                onValueChange={updateField('contactPhone')}
                label={t("contactPhone")}
                readOnly= {readOnly}

              />

              <TextBox
                value={parameter?.arpEMail}
                onValueChange={updateField('arpEMail')}
                label={t("email") + "*"}
                readOnly= {readOnly}
              />

              <TextBox
                value={parameter?.registrationName}
                onValueChange={updateField('registrationName')}
                label={t("registrationName") + "*"}
                hint='Firm official name or owner name'
                readOnly= {readOnly}
              />

              <TextBox
                value={parameter?.registerNumber}
                onValueChange={updateField('registerNumber')}
                label={t("registerNumber")}
                readOnly= {readOnly}

              />

              <TextBox
                value={parameter?.legalForm}
                onValueChange={updateField('legalForm')}
                label={t("legalForm")}
                readOnly= {readOnly}

              />
            </div>

            <div className='flex flex-col w-full gap-3 border shadow-md p-3'>
              <TextBox
                value={parameter?.arpIban}
                onValueChange={updateField('arpIban')}
                label={t("iban")}
                readOnly= {readOnly}
              />

              <TextBox
                value={parameter?.leitwegId}
                onValueChange={updateField('leitwegId')}
                label={t("leitwegId")}
                readOnly= {readOnly}
              />

              <TextBox
                value={parameter?.endpointId}
                onValueChange={updateField('endpointId')}
                label={t("endpointId")}
                readOnly= {readOnly}
              />

              <TextBox
                value={parameter?.arpVatId}
                onValueChange={updateField('arpVatId')}
                label={t("vatNumber")}
                readOnly= {readOnly}
              />

              <TextBox
                value={parameter?.arpTaxNr}
                onValueChange={updateField('arpTaxNr')}
                label={t("taxNr")}
                readOnly= {readOnly}
              />


            </div>

          </div>

        </div>


      )}>

    </Popup>
  );
};
