import { Button, DataGrid, DropDownButton } from "devextreme-react";
import { Column, ColumnChooser, DataGridRef, DataGridTypes, Editing, Export, FilterBuilderPopup, FilterPanel, HeaderFilter, Item, LoadPanel, Scrolling, SearchPanel, Selection, Sorting, Toolbar } from "devextreme-react/cjs/data-grid";
import { appInfo } from "../../app-info";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import { generalIncentivesDeleteRequest, generalIncentivesListRequest, registrantDetailstRequest, registrantsListRequest } from "../../api/requests";
import notify from "devextreme/ui/notify";
import './registrations.scss';
import IncentiveProductSearch from "../../components/library/general-incentives/searchProduct";
import { confirm } from "devextreme/ui/dialog";
import { RegistrationStatus } from "../../components/utils/contact-status/ContactStatus";
import { RegistrantPopup } from "../../components/library/registrations/popup";
import { DropDownButtonTypes } from "devextreme-react/cjs/drop-down-button";
import { formatDateDMY } from "../../utils/formats";



export const Registrations = () => {
  const gridRef = useRef<DataGridRef>(null);
  const {t} = useTranslation();
  const [registrations, setRegistrations] = useState([]);
  const [rowData, setRowData] = useState();
  const [options, setOptions] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);


  function getList(){
    gridRef.current?.instance().beginCustomLoading(t("loading"));
    registrantsListRequest().then((res)=>{
      if(res.resCode == 200){
        setRegistrations(res.dataSet || []);
      }else{
        notify(res.resMessage,"error");
      }
      gridRef.current?.instance().endCustomLoading();
      gridRef.current?.instance().filter(['requestStatus', '=', "0"]);
    })
  }

  useEffect(()=>{
    getList();
  },[])

  const [isPanelOpened, setPanelOpened] = useState(false);

  const changeSelectProductPopup = useCallback(() => {
        setPanelOpened(!isPanelOpened);

}, [isPanelOpened]);

  const onRowClick = useCallback(({ data,rowIndex }: DataGridTypes.RowClickEvent) => {
    registrantDetailstRequest(data.requestRef).then((resp)=> {
      if(resp.resCode == 200){
        setRowData(resp.requestDetails[0])
        setOptions(resp)
        setPanelOpened(true);
      }else{
        notify(resp.resMessage,"error")
      }
    })
  }, []);

  const handleContentReady = (e) => {
    setRowCount(e.component.totalCount());
  };

  const onRowRemoving = (e) => {
    notify("--silme işlemi henüz aktif değil--","error");
    /*
    const product = e.data;
    generalIncentivesDeleteRequest(product.segmentProductRef).then((res)=>{
      if(res.resCode == 200){
        notify(t("successfull"),"success");
      }else{
        notify(res.resMessage,"error");
        getList()
      }
    });
    */
  };

  const onSelectionChanged = (e) => {
    setSelectedRows(e.selectedRowsData);
};

async function deleteSelected(){
  notify("--silme işlemi henüz aktif değil--","error");
  /*
  const result = await confirm(t("areYouSureDeleting"),t("deleteProcess"));
    if (result) {
      gridRef.current?.instance().beginCustomLoading(t("loading"));
      var i = 0;
      selectedRows.forEach((row, index) => {

          generalIncentivesDeleteRequest(row["segmentProductRef"]).then((response)=>{
              if(response["resCode"]==200){
              }else{
                  notify(response["resMessage"],"error");
              }
              i++;
              if(i==selectedRows.length){
                  gridRef.current?.instance().endCustomLoading();
                  getList();
              }
          });
      });
    }
      */
}

const [reqStatus, setReqStatus] = useState("requested");
const filterStatusList = ["requested", "approved", "rejected"];
function getTranslatedStatusList() {
  return filterStatusList.map((status) => t(status));
}
const filterByStatus = useCallback((e: DropDownButtonTypes.SelectionChangedEvent) => {

  const { item: selected }: { item } = e;
  if (selected == t("requested")) {
    setReqStatus("requested");
    gridRef.current?.instance().filter(['requestStatus', '=', "0"]);
  } else if(selected==t('approved')){
    setReqStatus("approved");
    gridRef.current?.instance().filter(['requestStatus', '=', "1"]);
  }else if(selected == t('rejected')){
    setReqStatus("rejected");
    gridRef.current?.instance().filter(['requestStatus', '=', "2"]);
  }


}, []);

  return (
    <div className="view crm-contact-list">
      <div className="view-wrapper view-wrapper-contact-list list-page">
      <DataGrid
          className='grid theme-dependent'
          focusedRowEnabled
          dataSource={registrations}
          keyExpr="requestRef"
          onRowClick={onRowClick}
          onExporting={onExporting}
          onRowRemoving={onRowRemoving}
          onSelectionChanged={onSelectionChanged}
          noDataText={t("noData")}
          onContentReady={handleContentReady}
          allowColumnReordering
          allowColumnResizing
          showBorders
          ref={gridRef}
          editing={{
            mode: 'row',
            allowDeleting: false,
        }}>
          <Editing texts={{
                        editRow: t("edit"),
                        deleteRow: t("delete"),
                        confirmDeleteMessage: t("confirmDeleteMessage"),
                        confirmDeleteYes: t("yes"),
                        cancelRowChanges: t("no"),
                    }}  />

          <LoadPanel />
          <SearchPanel width={250} visible placeholder={t("customerSearch")}  />
          <ColumnChooser title={t("columnChooser")} enabled />
          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Scrolling mode='virtual' />
          <FilterPanel visible={true} />
          <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />

          <Toolbar>

          <Item location='before' locateInMenu='auto'>
              <DropDownButton
                items={getTranslatedStatusList()}
                stylingMode='text'
                text={t(reqStatus)}
                useSelectMode
                onSelectionChanged={filterByStatus}
              />
          </Item>

          <Item name='searchPanel' location='before'  />

          <Item
              location='before'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                hint={t("refresh")}
                stylingMode='text'
                onClick={getList}
              />
            </Item>

            <Item name='columnChooserButton' location='before' />

            <Item name='exportButton' location='before' />

            <Item location="before">
                <DropDownButton

                    visible={false}
                    text={"("+ selectedRows.length+") "+ t("transactions")}
                    icon="menu"
                    width={"175"}
                    items={[
                        { text: t("deleteSelected"), icon: 'trash', action: 'deleteSelected' },
                      ]}
                    onItemClick={(e) => {
                        if(e.itemData.action=="deleteSelected"){
                            deleteSelected();
                        }
                      }}
                />
            </Item>

            <Item location='center' locateInMenu='auto'>
              {rowCount} {t("records")}
            </Item>



          </Toolbar>

          <Column dataField="requestDate" caption={t("requestDate")} width={120} cellRender={(cell) => formatDateDMY(cell.value)}></Column>
          <Column
            dataField='requestStatus'
            caption={t("status")}
            width={130}
            cellRender={RegistrationStatus}
            alignment='left'
          />
            <Column dataField="shopUserEmail" caption={t("email")}></Column>
            <Column dataField="shopUserPhone" caption={t("phone")}></Column>
            <Column dataField="customerName" caption={t("firmName")}></Column>
            <Column dataField="customerCity" caption={t("city")}></Column>

            <Column dataField="customerTaxNr" caption={t("taxID")} visible={false}></Column>
            <Column dataField="customerVATNr" caption={t("taxNumber")} visible={false}></Column>
            <Column dataField="shippingAddress" caption={t("shippingAddress")} visible={false}></Column>
            <Column dataField="shippingCountry" caption={t("shippingCountry")} visible={false}></Column>


        </DataGrid>
      </div>
      <RegistrantPopup rowData={rowData} options={options}  visible={isPanelOpened} onHiding={changeSelectProductPopup} refresh={getList} />
    </div>
  );
};

const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === 'pdf') {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save('Registrants.pdf');
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Page1');

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Registrants.xlsx');
      });
    });
    e.cancel = true;
  }
};
