import React, { useState, useRef, useEffect, useCallback } from 'react';
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import DataGrid, {
  Column,
  Toolbar,
  Item,
  Export,
  LoadPanel,
  SearchPanel,
  ColumnChooser,
  Scrolling,
  Selection,
  FilterPanel,
  FilterBuilderPopup,
  HeaderFilter,
  Sorting,
  DataGridRef,
  DataGridTypes,

} from 'devextreme-react/data-grid';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import Button from 'devextreme-react/button';
import { DateBox, SelectBox } from 'devextreme-react';
import axios from 'axios';
import { t } from 'i18next';
// import { BankAccountsModel } from '../../types/crm-contact';
// import { newBankAccountsModel } from '../../shared/constants';
// import { BankAccountsEditPopup } from '../../components/library/bank-accounts/popup';
import { DataGridCell } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { appInfo } from '../../../app-info';

interface BankAccount {
  iban: string;
  id: string;
  accountName: string;
  accountNumber:string;
}

export const Transactions = () => {
  const [transactionsList, setTransactionsList] = useState([]);
  const [selectedBank, setSelectedBank] = useState<string | null>(null);
  const [bankAccountsList, setBankAccountsList] = useState<BankAccount[]>([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const gridRef = useRef<DataGridRef>(null);
  const [bankAccounts, setBankAccount] = useState();
  const [isPanelOpened, setPanelOpened] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [banks, setBanks] = useState<{ name: string; blz: string; bic: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    mainRefresh();
    getBankAccounts();
  }, []);

  useEffect(() => {
    if (selectedBank || (startDate && endDate)) {
      handleFilter();
    }

  fetchBanks();
  }, [selectedBank, startDate, endDate,searchQuery]);

  function mainRefresh() {
    axios.get('https://pay.mobiserver.de:4243/api/transactions')
      .then((res) => {
        setTransactionsList(res.data.transactions);
        gridRef.current?.instance().endCustomLoading();
      })
      .catch((error) => {
        console.error("Transactions verileri alınırken hata oluştu:", error);
      });
  }

    const fetchBanks = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`https://pay.mobiserver.de:4243/api/banks`);
            setBanks(Object.values(response.data.banks)); //Bank tipine uygun veri geldiğinden emin olun
        } catch (error) {
            console.error('Error fetching banks:', error);
            setError('Bankaları getirirken bir hata oluştu.');
        } finally {
            setLoading(false);
        }
    };

  // function getBankAccounts() {
  //   axios.get("https://pay.mobiserver.de:4243/api/banks-with-accounts")
  //     .then((res) => {
  //       const ibanList = res.data.map((account: BankAccount) => ({
  //         iban: account.iban + ' - ' + account.accountName,
  //         id: account.id
  //       }));
  //       setBankAccountsList(ibanList);
  //     })
  //     .catch((error) => {
  //       console.error("Bankalar alınırken bir hata oluştu:", error);
  //     });
  // }
  function getBankAccounts() {
    axios.get("https://pay.mobiserver.de:4243/api/banks-with-accounts")
      .then((res) => {
        const ibanList = res.data.map((account: BankAccount) => ({
          iban: account.iban !== undefined && account.iban !== null
            ? account.iban + ' - ' + account.accountName  // IBAN varsa IBAN + AccountName göster
            : account.accountNumber + ' - ' + account.accountName, // IBAN yoksa AccountNumber + AccountName göster
          id: account.id
        }));
        setBankAccountsList(ibanList);
      })
      .catch((error) => {
        console.error("Bankalar alınırken bir hata oluştu:", error);
      });
  }


  const handleFilter = useCallback(() => {
    let filteredData = transactionsList;
    if (selectedBank) {
      const selectedIban = selectedBank.split(' - ')[0];
      const selectedAccountId = bankAccountsList.find((account) => account.iban.startsWith(selectedIban))?.id;
      filteredData = filteredData.filter((transaction: any) => transaction.accountId === selectedAccountId);
    }

    if (startDate && endDate) {
      const formatDate = (date: Date) => date.toLocaleDateString('en-CA'); // Tarihi yyyy-MM-dd formatına dönüştür

      filteredData = filteredData.filter((transaction: any) => {
        const bookingDate = new Date(transaction.bankBookingDate);
        const formattedBookingDate = formatDate(bookingDate); // Filtreleme için bankBookingDate formatı
        return formattedBookingDate >= formatDate(startDate) && formattedBookingDate <= formatDate(endDate);
      });
    }

    setFilteredTransactions(filteredData);
  }, [selectedBank, startDate, endDate, transactionsList, bankAccountsList]);

  const handleBankChange = (e: any) => {
    setSelectedBank(e.value);
  };

  const handleStartDateChange = (e: any) => {
    setStartDate(e.value);
  };

  const handleEndDateChange = (e: any) => {
    setEndDate(e.value);
  };

  const changePanelOpened = useCallback(() => {
    setPanelOpened(!isPanelOpened);
  }, [isPanelOpened]);

  const onAddContactClick = useCallback(() => {
   // setBankAccount(newBankAccountsModel);
    setPanelOpened(true);
  }, []);

  const onRowClick = useCallback(({ data }: any) => {
    setBankAccount(data);
    setPanelOpened(true);
  }, []);

  return (
    <div className="view crm-contact-list">
      <div className="view-wrapper view-wrapper-contact-list list-page">
      <DataGrid
      className="grid theme-dependent"
      height="100%"
      focusedRowEnabled
      dataSource={filteredTransactions.length > 0 ? filteredTransactions : []}  // Filtrelenmiş veriler yoksa boş liste
      keyExpr="id"
      onExporting={onExporting}
      noDataText={t("noData")}
      allowColumnReordering
      allowColumnResizing
      showBorders
      ref={gridRef}
    >
          <FilterPanel visible />
          <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />
          <LoadPanel showPane={false} />
          <SearchPanel visible placeholder={t("customerSearch")} />
          <ColumnChooser enabled />
          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <Selection selectAllMode="allPages" showCheckBoxesMode="always" mode="multiple" />
          <HeaderFilter visible />
          <Sorting mode="multiple" />
          <Scrolling mode="virtual" />

          <Toolbar>
            <Item name="searchPanel" location="before" />

            <Item location="before">
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <SelectBox
                  items={bankAccountsList.map(bank => bank.iban)}
                  value={selectedBank}
                  onValueChanged={handleBankChange}
                  placeholder={t("Select Bank")}
                  width={300}
                                  />
                </div>
                </Item>
                <Item location="before">
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <DateBox
                  type="date"
                  displayFormat="dd.MM.yyyy"
                  placeholder={t("Start Date")}
                  value={startDate||undefined}
                  onValueChanged={handleStartDateChange}
                  calendarOptions={{
                    firstDayOfWeek: 1,
                  }}
                />
                <DateBox
                  type="date"
                  displayFormat="dd.MM.yyyy"
                  placeholder={t("End Date")}
                  value={endDate||undefined}
                  onValueChanged={handleEndDateChange}
                  calendarOptions={{
                    firstDayOfWeek: 1,
                  }}
                />
              </div>
            </Item>

            <Item location="before">
              <Button icon="refresh" stylingMode="text" onClick={mainRefresh} />
            </Item>
            <Item location='before' name='columnChooserButton' />
            <Item location='before' name='exportButton' />

            {/* <Item location="after">
              <Button icon="plus" text={t("new")} type="default" stylingMode="contained" onClick={onAddContactClick} />
            </Item> */}
          </Toolbar>

          <Column dataField="id" caption="Transaction ID" />
          <Column dataField="accountId" caption="Account ID" />
          <Column dataField="valueDate" caption="Value Date" />
          <Column dataField="amount" caption="Amount" />
          <Column dataField="currency" caption="Currency" />
          <Column dataField="purpose" caption="Purpose" />
          <Column dataField="bankBookingDate" caption="Bank Booking Date" />
          <Column dataField="type" caption="Account Type" />
        </DataGrid>

        {/* <BankAccountsEditPopup
          mainRefresh={mainRefresh}
          rowData={bankAccounts}
          isPanelOpened={isPanelOpened}
          changePanelOpened={changePanelOpened}
        /> */}
      </div>
    </div>
  );
};

const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === 'pdf') {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save('Products.pdf');
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Page1');

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Products.xlsx');
      });
    });
    e.cancel = true;
  }
};

const dropDownOptions = { width: 'auto',  };