import TabPanel, { Item as TabPanelItem } from 'devextreme-react/tab-panel';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSettingJSONRequest, sendSettingJSONRequest } from '../../../api/requests';
import notify from 'devextreme/ui/notify';
import { Button, TextBox } from 'devextreme-react';
import { validateEmail } from '../../../utils/formats';


type CompanyInfoModel = {
  companyLeitwegId?: string;
  companyEndpointId?: string;
  companyName?: string;
  companyStreet?: string;
  companyCity?: string;
  companyPostCode?: string;
  companyCountryCode?: string;
  companyCountry?: string;
  companyVatId?: string;
  registrationName?: string;
  registerNumber?: string;
  legalForm?: string;
  contactName?: string;
  contactPhone?: string;
  contactEMail?: string;
  companyIban?: string;
};

const emptyCompanySettings: CompanyInfoModel = {
  companyLeitwegId: "",
  companyEndpointId: "",
  companyName: "",
  companyStreet: "",
  companyCity: "",
  companyPostCode: "",
  companyCountryCode: "",
  companyCountry: "",
  companyVatId: "",
  registrationName: "",
  registerNumber: "",
  legalForm: "",
  contactName: "",
  contactPhone: "",
  contactEMail: "",
  companyIban: "",
}

export const OurParameters = ({ }) => {

  const [selectedTab, setSelectedTab] = useState(0);


  const [company, setCompany] = useState<CompanyInfoModel>()
  const [companyState, setCompanyState] = useState<CompanyInfoModel>()
  const [readOnly, setReadOnly] = useState(true)
  const { t } = useTranslation()

  const updateField = (key) => (value) => {
    setCompany(prev => ({
      ...prev,
      [key]: value
    }));
  };


  function getData() {
    getSettingJSONRequest(100,1).then((resp) => {
      if(resp.resCode == 200){
        if(resp.dataSet&&resp.dataSet[0]&&resp.dataSet[0]["settingJson"]){
            try{
                setCompany(JSON.parse(resp.dataSet[0]["settingJson"]))
                setCompanyState(JSON.parse(resp.dataSet[0]["settingJson"]))
            }catch(e){
                setCompany(emptyCompanySettings)
                setCompanyState(emptyCompanySettings)
            }

        }
    }else{
        setCompany(emptyCompanySettings)
        setCompanyState(emptyCompanySettings)
        notify(resp.resMessage,"error")
    }
    })
  }

  function validator(company): boolean {
    if(company){
      return !!(
        company?.companyName &&
        company.companyStreet &&
        company.companyCity &&
        company.companyPostCode &&
        company.companyCountry &&
        company.companyCountryCode &&
        company.companyVatId &&
        company.legalForm &&
        company.contactName &&
        company.contactPhone &&
        company.registrationName &&
        company.registerNumber &&
        company.companyIban &&
        company.contactEMail
    );
    }else{
      return true;
    }

}

  function handleSave() {

    if (!validator(company)) {
      notify(t("sentence3"), "error");
      return;
    }

    if(!validateEmail(company?.contactEMail)){
      notify("Email format is not valid!","error");
      return;
    }

    const settingJson = JSON.stringify(company)
    sendSettingJSONRequest(100, 1, settingJson).then((resp)=>{
        if(resp.resCode == 200){
            notify(t("successfull"),"success")
            setReadOnly(true)
            setCompanyState(company)
        }else{
            notify(resp.resMessage,"error")
        }
    })

  }


  useEffect(() => {
    getData();
  }, [])



  return (
    <div className='view crm-contact-list h-full w-full'>
      <div className=' p-8 h-full w-full rounded-lg overflow-auto'>
        <div className=' p-5 bg-white  rounded-lg border border-gray-300 '>

          <div className='flex justify-end'>
            <div className='flex flex-row gap-x-2'>
              <Button visible={readOnly} text={t("edit")} icon='edit' stylingMode='contained' type='default' onClick={() => { setReadOnly(false) }} />
              <Button visible={!readOnly} text={t("save")} icon='save' stylingMode='contained' type='default' onClick={handleSave} />
              <Button visible={!readOnly} text={t("cancel")} icon='close' stylingMode='contained' style={{ backgroundColor: "gray" }} type='default'
               onClick={() => {
                 setReadOnly(true)
                 setCompany(companyState)
                 }} />
            </div>
          </div>

          <div className=''>
            <TabPanel
              selectedIndex={selectedTab}
              onSelectionChanged={(e) => {
                const selectedIndex = e.component.option('selectedIndex');
                if (typeof selectedIndex === 'number') {
                  setSelectedTab(selectedIndex);
                }
              }}
            >
              <TabPanelItem title={t("company")}>
              <div
                className={`${ validator(company) ? "hidden" : "flex" } p-2 mt-2 bg-red-200 shadow-sm`} >
                {t("sentence3")}
              </div>

                <div className='flex flex-col md:flex-row w-full gap-4 mt-1'>

                  <div className="p-5 my-2 w-96 rounded-md flex flex-col  gap-4 border shadow-md">
                    <div>
                      <b>{t("company")}</b>
                      <hr></hr>
                    </div>

                    <TextBox
                      label={t("companyName")+"*"}
                      value={company?.companyName}
                      readOnly={readOnly}
                      onValueChange={updateField('companyName')}
                    />
                    <TextBox
                      label={t("contactName")+"*"}
                      value={company?.contactName}
                      readOnly={readOnly}
                      onValueChange={updateField('contactName')}
                    />
                    <TextBox
                      label={t("contactPhone")+"*"}
                      value={company?.contactPhone}
                      readOnly={readOnly}
                      onValueChange={updateField('contactPhone')}
                    />
                    <TextBox
                      label={t("contactEmail")+"*"}
                      value={company?.contactEMail}
                      readOnly={readOnly}
                      onValueChange={updateField('contactEMail')}
                    />




                  </div>

                  <div className="p-5 my-2 w-96 rounded-md flex flex-col  gap-4 border shadow-md">
                    <div>
                      <b>{t("address")}</b>
                      <hr></hr>
                    </div>
                    <TextBox
                      label={t("street")+"*"}
                      value={company?.companyStreet}
                      readOnly={readOnly}
                      onValueChange={updateField('companyStreet')}
                    />
                    <TextBox
                      label={t("city")+"*"}
                      value={company?.companyCity}
                      readOnly={readOnly}
                      onValueChange={updateField('companyCity')}
                    />

                    <TextBox
                      label={t("postCode")+"*"}
                      value={company?.companyPostCode}
                      readOnly={readOnly}
                      onValueChange={updateField('companyPostCode')}
                    />

                    <TextBox
                      label={t("countryCode")+"*"}
                      value={company?.companyCountryCode}
                      readOnly={readOnly}
                      onValueChange={updateField('companyCountryCode')}
                    />

                    <TextBox
                      label={t("country")+"*"}
                      value={company?.companyCountry}
                      readOnly={readOnly}
                      onValueChange={updateField('companyCountry')}
                    />




                  </div>

                  <div className="p-5 my-2 w-96 rounded-md flex flex-col  gap-4 border shadow-md">
                    <div>
                      <b>{t("legalInfo")}</b>
                      <hr></hr>
                    </div>
                    <TextBox
                      label={t("vatId")+"*"}
                      className='shadow-md'
                      value={company?.companyVatId}
                      readOnly={readOnly}
                      onValueChange={updateField('companyVatId')}
                    />
                    <TextBox
                      label={t("registrationName")+"*"}
                      value={company?.registrationName}
                      readOnly={readOnly}
                      onValueChange={updateField('registrationName')}
                    />

                    <TextBox
                      label={t("registerNumber")+"*"}
                      value={company?.registerNumber}
                      readOnly={readOnly}
                      onValueChange={updateField('registerNumber')}
                    />
                    <TextBox
                      label={t("legalForm")+"*"}
                      value={company?.legalForm}
                      readOnly={readOnly}
                      onValueChange={updateField('legalForm')}
                    />


                  </div>

                  <div className="p-5 my-2 w-96 rounded-md flex flex-col  gap-4 border shadow-md">
                    <div>
                      <b>{t("additionalInfos")}</b>
                      <hr></hr>
                    </div>
                    <TextBox
                      label={t("IBAN")+"*"}
                      value={company?.companyIban}
                      readOnly={readOnly}
                      onValueChange={updateField('companyIban')}
                    />
                    <TextBox
                      label={t("leitwegId")}
                      value={company?.companyLeitwegId}
                      readOnly={readOnly}
                      onValueChange={updateField('companyLeitwegId')}
                    />
                    <TextBox
                      label={t("endpointId")}
                      value={company?.companyEndpointId}
                      readOnly={readOnly}
                      onValueChange={updateField('companyEndpointId')}
                    />
                  </div>

                </div>

              </TabPanelItem>


            </TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
};
















