import React, { useState, useEffect, useCallback, useRef } from 'react';
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import './b2b-customer-list.scss';
import DataGrid, {
  Sorting, Selection, HeaderFilter, Scrolling, SearchPanel,
  ColumnChooser, Export, Column, Toolbar, Item, LoadPanel,
  DataGridTypes,
  FilterPanel,
  FilterBuilderPopup,
  DataGridRef
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import DropDownButton, { DropDownButtonTypes } from 'devextreme-react/drop-down-button';

import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';

import { ContactStatus as ContactStatusType, CustomerModel } from '../../types/crm-contact';

import { ContactPanel } from '../../components';
import { ContactStatus } from '../../components';

import { newContact } from '../../shared/constants';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { customersRequest, customerUpdateStatusRequest } from '../../api/requests';
import { customerListFilterFromSession, customerListFilterToSession, customerListSelectFromSession, customerListSelectToSession } from '../../api/sessions';
import { useTranslation } from 'react-i18next';
import { useCustomerContext } from '../../contexts/customers';
import { appInfo } from '../../app-info';
import { B2BPopup } from '../../components/library/b2b/popup';
import { confirm } from 'devextreme/ui/dialog';



export const B2BCustomerList = () => {
  const { t } = useTranslation();
  const { customers, setCustomers } = useCustomerContext();
  const filterStatusList = ["all", "active", "passive"];
  const [customerActive, setCustomerActive] = useState("active");
  const [rowCount, setRowCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);

  const [gridDataSource, setGridDataSource] = useState<DataSource<CustomerModel[], string>>();
  const [isPanelOpened, setPanelOpened] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [formDataDefaults, setFormDataDefaults] = useState({ ...newContact });
  const gridRef = useRef<DataGridRef>(null);

  const [rowData, setRowData] = useState();

  let newContactData: CustomerModel;

  const [refreshKey, setRefreshKey] = useState(0);
  function mainRefresh(){
    setRefreshKey(a => a + 1);
    customersRequest(0).then((res)=>{
      setCustomers(res.data.dataSet);
      gridRef.current?.instance().endCustomLoading();
      setTimeout(() => {
        updateFilter();
      }, 100);
    })
  }

  function refreshFromApi(){
    customersRequest(0)
    .then(response => {
      var data = response.data.dataSet;
     // customersToSession(JSON.stringify(data))
      setCustomers(data);
      setGridDataSource(new DataSource({
        key: 'combinedCode',
        load: () => {
          const modifiedCustomers = customers.map(customer => ({
            ...customer,
            combinedCode: `${customer.erpCustomerRef}-${customer.erpShippingAdrRef}`
          }));
          return JSON.parse(JSON.stringify(modifiedCustomers)); // API'den gelen veriyi yükle
        },
      }));

      setTimeout(() => {
        gridRef.current?.instance().filter(['customerActive', '=', "1"]);
      }, 100);

    })
    .catch(error => {
      console.error('Error fetching data:', error);
      // Hata durumunda gerekli işlemleri yap
    });
  }

  function updateFilter(){
    if(customerListFilterFromSession()=="0"){
      gridRef.current?.instance().filter(['customerActive', '=', "0"]);
    }else if(customerListFilterFromSession()=="1"){
      gridRef.current?.instance().filter(['customerActive', '=', "1"]);
    }else{
      gridRef.current?.instance().clearFilter()
    }

    if(customerListFilterFromSession()=="0"){
      setCustomerActive("passive")
    }else if(customerListFilterFromSession()=="1"){
      setCustomerActive("active")
    }else{
      setCustomerActive("all")
    }
  }


  useEffect(() => {

if(customers?.length==0){
  customersRequest(0).then((e)=>{
    setCustomers(e.data.dataSet);
    setTimeout(() => {
      updateFilter();
    }, 100);
  })
}else{

  setGridDataSource(new DataSource({
    key: 'combinedCode',
    load: () => {
      const modifiedCustomers = customers.map(customer => ({
        ...customer,
        combinedCode: `${customer.erpCustomerRef}-${customer.erpShippingAdrRef}`
      }));
      return JSON.parse(JSON.stringify(modifiedCustomers)); // API'den gelen veriyi yükle
    },
  }));
  setTimeout(() => {
    updateFilter();
  }, 100);
  }

  }, [JSON.stringify(customers)]);


  const changePopupVisibility = useCallback((isVisble) => {
    setPopupVisible(isVisble);
  }, []);

  const changePanelOpened = useCallback(() => {
    setPanelOpened(!isPanelOpened);
    gridRef.current?.instance().navigateToRow(customerListSelectFromSession());
  }, [isPanelOpened]);

  const changePanelPinned = useCallback(() => {
    gridRef.current?.instance().updateDimensions();
  }, []);

  const onAddContactClick = useCallback(() => {
    setPopupVisible(true);
  }, []);

  const onRowClick = useCallback(({ data,rowIndex }: DataGridTypes.RowClickEvent) => {
    setRowData(data);
    setPanelOpened(true);
    var ss =  data.combinedCode;
    customerListSelectToSession(ss);
  }, []);


  const filterByStatus = useCallback((e: DropDownButtonTypes.SelectionChangedEvent) => {
    const { item: selected }: { item: FilterContactStatus } = e;
    if (selected == t("all")) {
      customerListFilterToSession("2")
      setCustomerActive("all");
      gridRef.current?.instance().clearFilter();
    } else if(selected==t('active')){
      gridRef.current?.instance().filter(['customerActive', '=', "1"]);
      customerListFilterToSession("1")
      setCustomerActive("active");
    }else if(selected == t('passive')){
      gridRef.current?.instance().filter(['customerActive', '=', "0"]);
      customerListFilterToSession("0")
      setCustomerActive("passive");
    }

  }, []);

  function getTranslatedStatusList() {
    return filterStatusList.map((status) => t(status));
  }

  const refresh = useCallback(() => {
    mainRefresh();
    gridRef.current?.instance().beginCustomLoading(t("loading"))

  }, []);

  const onDataChanged = useCallback((data) => {
    newContactData = data;
  }, []);

  const onSaveClick = useCallback(() => {
    notify({
      message: `New contact saved`,
      position: { at: 'bottom center', my: 'bottom center' }
    },
    'success'
    );

    setFormDataDefaults({ ...formDataDefaults });
    setPopupVisible(false);
  }, []);

  const handleContentReady = (e) => {
    setRowCount(e.component.totalCount());
  };

  const onSelectionChanged = (e) => {
    setSelectedRows(e.selectedRowsData);
  };

  async function changeStatus(status){
    const result = await confirm(
      status==1?t("activeSelectedConfirm"):("passiveSelectedConfirm"),
      status==1?t("activeSelected"):t("passiveSelected"));
    if (result) {
      gridRef.current?.instance().beginCustomLoading(t("loading"));
      var i = 0;
      selectedRows.forEach((row, index) => {

        customerUpdateStatusRequest(row,status).then((response)=>{
              if(response["resCode"]==200){
              }else{
                  notify(response["resMessage"],"error");
              }
              i++;
              if(i==selectedRows.length){
                  gridRef.current?.instance().endCustomLoading();
                  mainRefresh();
              }
          });
      });
    }
  }

  return (
    <div className='view crm-contact-list'>
      <div className='view-wrapper view-wrapper-contact-list list-page'>
        <DataGrid
          className='grid theme-dependent'
          height='100%'
          focusedRowEnabled
          dataSource={gridDataSource}
          onRowClick={onRowClick}
          noDataText={t("noData")}
          onExporting={onExporting}
          onContentReady={handleContentReady}
          onSelectionChanged={onSelectionChanged}
          allowColumnReordering
          allowColumnResizing
          showBorders
          ref={gridRef}>

          <LoadPanel showPane={false}  />
          <SearchPanel visible placeholder={t("customerSearch")}  />
          <ColumnChooser enabled />
          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <Selection selectAllMode='allPages' showCheckBoxesMode='always' mode='multiple' />
          <FilterPanel visible={true} />
          <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />
          <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Scrolling mode='virtual' />

          <Toolbar>

            <Item location='before' locateInMenu='auto'>
              <DropDownButton
                items={getTranslatedStatusList()}
                stylingMode='text'
                text={t(customerActive)}
                dropDownOptions={dropDownOptions}
                useSelectMode
                onSelectionChanged={filterByStatus}
              />
            </Item>

            <Item name='searchPanel' location='before' />

            <Item
              location='before'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                text='Refresh'
                stylingMode='text'
                onClick={refresh}
              />
            </Item>

            <Item name='columnChooserButton' location='before' />

            <Item name='exportButton' location='before'/>

            <Item location="before">
                <DropDownButton
                    visible={selectedRows.length>0}
                    text={"("+ selectedRows.length+") "+ t("transactions")}
                    icon="menu"
                    width={"175"}
                    items={[
                        { text: t("activeSelected"), icon: 'check', action: 'activeSelected' },
                        { text: t("passiveSelected"), icon: 'minus', action: 'passiveSelected' },
                      ]}
                    onItemClick={(e) => {
                        if(e.itemData.action=="activeSelected"){
                            changeStatus(1);
                        }
                        if(e.itemData.action=="passiveSelected"){
                            changeStatus(0);
                        }
                      }}
                />
            </Item>

            <Item location='center' locateInMenu='auto'>
                {rowCount} {t("records")}
            </Item>

            <Item location='after' locateInMenu='auto'>
              <Button
                icon='plus'
                text='Add Contact'
                type='default'
                stylingMode='contained'
                visible={false}
                onClick={onAddContactClick}
              />
            </Item>


          </Toolbar>
          <Column
            dataField='customerActive'
            caption={t("status")}
            hidingPriority={19}
            width={100}
            cellRender={ContactStatus}
          />
          <Column
            dataField='customerCode'
            caption={t("customerCode")}
            sortOrder='asc'
            width={200}
            hidingPriority={18}
          />
          <Column
            dataField='customerName'
            caption={t("name")}
            hidingPriority={20}
            cellRender={cellNameRender}
          />
          <Column
            dataField='erpCustomerCity'
            caption={t("city")}
            hidingPriority={17}
          />

          <Column
            caption={t("groupName")}
            dataField="customerGroupName"
            hidingPriority={16}
          />

          <Column
            caption={t("groupCode")}
            dataField="customerGroupCode"
            hidingPriority={15}
          />

          <Column
            dataField='productGroupName'
            caption="Product Group Name"
            hidingPriority={14}
            visible={false}
          />

          <Column
            dataField='productGroupCode'
            caption="Product Group Code"
            hidingPriority={13}
            visible={false}
          />

          <Column
            dataField={t("comment")}
            caption="Comment"
            hidingPriority={12}
          />


        </DataGrid>
        {
          /*
                <ContactPanel mainRefresh={mainRefresh}  rowData={rowData} isOpened={isPanelOpened} changePanelOpened={changePanelOpened} changePanelPinned={changePanelPinned} />

          */
        }
        <B2BPopup mainRefresh={mainRefresh} closePopup={changePanelOpened}  rowData={rowData} isPanelOpened={isPanelOpened} changePanelOpened={changePanelOpened} isNewRecord={false} ></B2BPopup>

      </div>
    </div>
  );
};



type FilterContactStatus = ContactStatusType | 'active';


const cellNameRender = (cell: DataGridTypes.ColumnCellTemplateData) => (
  <div className='name-template'>
    <div>{cell.data.customerName}</div>
    <div className='position'>{cell.data.position}</div>
  </div>
);


const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === 'pdf') {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save('Customers.pdf');
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Page1');

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Customers.xlsx');
      });
    });
    e.cancel = true;
  }
};

const dropDownOptions = { width: 'auto' };