import TabPanel, { Item as TabPanelItem } from 'devextreme-react/tab-panel';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import notify from 'devextreme/ui/notify';
import { Button, DropDownBox, SelectBox, TextBox } from 'devextreme-react';
import { Eye, EyeOff } from 'react-feather';
import { base64Decode, base64Encode } from '../../utils/base64-decoder';
import { ftpTESTRequest, getSettingJSONRequest, sendSettingJSONRequest } from '../../api/requests';


type FTPSettingsModel = {

    ftp_User?: string;
    ftp_Host?: string;
    ftp_Port?: string;
    ftp_Password?: string;
    ftp_Secure?: number;

};

const emptyFtpSettings: FTPSettingsModel = {
    ftp_User: "",
    ftp_Host: "",
    ftp_Port: "",
    ftp_Password: "",
    ftp_Secure: 0,
}


export const FTPSettings = ({ }) => {

  const [selectedTab, setSelectedTab] = useState(0);




  const [ftpSettings, setFtpSettings] = useState<FTPSettingsModel>()
  const [ftpSettingsState, setFtpSettingsState] = useState<FTPSettingsModel>()
  const [readOnlyFtpSettings, setReadOnlyFtpSettings] = useState(true)

  const { t } = useTranslation()

  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };


  const updateFieldFtp = (key) => (value) => {
    setFtpSettings(prev => ({
      ...prev,
      [key]: value
    }));
  };


  function getData() {

    getSettingJSONRequest(200,1).then((resp)=>{
        if(resp.resCode == 200){
            if(resp.dataSet&&resp.dataSet[0]&&resp.dataSet[0]["settingJson"]){
                try{
                    setFtpSettings(JSON.parse(resp.dataSet[0]["settingJson"]))
                    setFtpSettingsState(JSON.parse(resp.dataSet[0]["settingJson"]))
                }catch(e){
                    setFtpSettings(emptyFtpSettings)
                    setFtpSettingsState(emptyFtpSettings)
                }

            }
        }else{
            setFtpSettings(emptyFtpSettings)
            setFtpSettingsState(emptyFtpSettings)
            notify(resp.resMessage,"error")
        }
    })
  }



  function handleSaveFtpSettings() {
    const settingJson = JSON.stringify(ftpSettings)
    sendSettingJSONRequest(200, 1, settingJson).then((resp)=>{
        if(resp.resCode == 200){
            notify(t("successfull"),"success")
            setReadOnlyFtpSettings(true)
            setFtpSettingsState(ftpSettings)
        }else{
            notify(resp.resMessage,"error")
        }
    })

  }


  useEffect(() => {
    getData();
  }, [])

  const [testClicked, setTestClicked] = useState(false)
  function handleTestClick(){
    setTestClicked(true);
    ftpTESTRequest().then((resp)=>{
      if(resp.resCode == 200){
        notify(t("connectionSuccessfully"),"success")
      }else{
        notify(resp.resMessage,"error")
      }
      setTestClicked(false)
    })
  }


  return (
    <div className='view crm-contact-list h-full w-full'>
      <div className=' p-8 h-full w-full rounded-lg overflow-auto'>
        <div className=' p-5 bg-white  rounded-lg border border-gray-300 '>



          <div className=''>
            <TabPanel
              selectedIndex={selectedTab}
              onSelectionChanged={(e) => {
                const selectedIndex = e.component.option('selectedIndex');
                if (typeof selectedIndex === 'number') {
                  setSelectedTab(selectedIndex);
                }
              }}
            >


              <TabPanelItem title={t("imageServer")}>

                <div className='flex'>

                <div className='flex flex-col md:flex-row w-full gap-4 mt-3'>

                    <div className="p-5 my-2 rounded-md flex flex-col gap-4 border shadow-md">
                    <div>
                        <b>{t("imagesFTPInfo")}</b>
                        <hr></hr>
                    </div>

                    <div className='flex flex-col  md:flex-row gap-8'>
                        <div className='flex flex-col w-72 gap-4'>
                        <TextBox
                            label={t("host")}
                            value={ftpSettings?.ftp_Host}
                            readOnly={readOnlyFtpSettings}
                            onValueChange={updateFieldFtp('ftp_Host')}
                        />

                        <TextBox
                            label={t("userName")}
                            value={ftpSettings?.ftp_User}
                            readOnly={readOnlyFtpSettings}
                            onValueChange={updateFieldFtp('ftp_User')}
                        />


                        <div className=''>
                            <div style={{ position: 'relative' }}>
                            <TextBox
                                className='relative'
                                label={t("password")}
                                value={base64Decode(ftpSettings?.ftp_Password)}
                                mode={isPasswordVisible ? 'text' : 'password'}
                                inputAttr={{ autocomplete: 'new-password' }}
                                onValueChange={(e)=> updateFieldFtp('ftp_Password')(base64Encode(e))}
                                readOnly={readOnlyFtpSettings}
                            />
                            <div
                                onClick={togglePasswordVisibility}
                                style={{
                                position: 'absolute',
                                right: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer'
                                }}
                            >
                                {isPasswordVisible ? <EyeOff /> : <Eye />}
                            </div>
                            </div>
                        </div>

                        <SelectBox
                            label={t("secure")}
                            value={ftpSettings?.ftp_Secure ?? 0}
                            readOnly={readOnlyFtpSettings}
                            items={[
                            { value: 0, label: t("no") },
                            { value: 1, label: t("yes") },
                            ]}
                            displayExpr="label"
                            valueExpr="value"
                            onValueChange={updateFieldFtp('ftp_Secure')}
                        />

                        <TextBox
                            visible={false}
                            label={t("port")}
                            value={ftpSettings?.ftp_Port}
                            readOnly={readOnlyFtpSettings}
                            onValueChange={updateFieldFtp('ftp_Port')}
                        />

                        </div>


                    </div>

                    </div>

                    </div>

                <div className='flex justify-end mt-4 gap-2'>
                <Button
                visible={readOnlyFtpSettings}
                disabled={testClicked}
                icon='refresh'
                text={t("connectionTest")}
                onClick={handleTestClick}
                />

            <div className='flex flex-row gap-x-2'>
              <Button visible={readOnlyFtpSettings} text={t("edit")} icon='edit' stylingMode='contained' type='default' onClick={() => { setReadOnlyFtpSettings(false) }} />
              <Button visible={!readOnlyFtpSettings} text={t("save")} icon='save' stylingMode='contained' type='default' onClick={handleSaveFtpSettings} />
              <Button visible={!readOnlyFtpSettings} text={t("cancel")} icon='close' stylingMode='contained' style={{ backgroundColor: "gray" }} type='default'
               onClick={() => {
                 setReadOnlyFtpSettings(true)
                 setFtpSettings(ftpSettingsState)
                 }} />
            </div>
          </div>
                </div>




              </TabPanelItem>

            </TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
};
















